import React, { useState, useEffect } from 'react';

// eslint-disable-next-line react/prop-types
const RotatingText = ({ className, textArray }) => {
    const [index, setIndex] = useState(0);
    const [visible, setVisible] = useState(true); // To manage visibility
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        setVisible(false); // Trigger fade-out
        setTimeout(() => {
          // eslint-disable-next-line react/prop-types
          setIndex((prevIndex) => (prevIndex + 1) % textArray.length); // Change text after fade-out
          setVisible(true); // Trigger fade-in after text change
        }, 500); // Match the fade-out duration (500ms)
      }, 3000); // Rotate text every 3 seconds
  
      return () => clearInterval(intervalId);
    // eslint-disable-next-line react/prop-types
    }, [textArray.length]);
  
    return (
      <strong className={`${className} ${visible ? 'fade-in' : 'fade-out'}`}>
        {textArray[index]}
      </strong>
    );
  };

export default RotatingText;
