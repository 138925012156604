import React, { useState } from "react";
import MenuBar from "./comman/MenuBar";
import Footer from "./comman/Footer";
import RequestEvaluation from "./comman/RequestEvaluation";
import TalkExpert from "./comman/TalkExpert";
import { Container, Row, Col } from "react-bootstrap";
import RotatingText from "./comman/RotatingText";
import {
  dots,
  founder2,
  business,
  three_fourth,
  fifty,
  rip,
  close,
  heart,
  peaked,
  construction,
  border_center,
  quality,
  regulatory,
  performance,
} from "../assets";
import Accordion from "react-bootstrap/Accordion";
import ApproachSection from "./comman/ApproachSection";
import bannerImage from "../assets/banner1.webp";

const WhatWeDo = () => {
  const [isPopupEvaluationOpen, setPopupEvaluationOpen] = useState(false);
  const [isPopupTalkExportOpen, setPopupTalkExportOpen] = useState(false);
  
  const handleOpenPopupEvaluation = () => {
    setPopupEvaluationOpen(true);
  };
  const handleClosePopupEvaluation = () => {
    setPopupEvaluationOpen(false);
  };

  const handleOpenPopupTalkExport = () => {
    setPopupTalkExportOpen(true);
  };
  const handleClosePopupTalkExport = () => {
    setPopupTalkExportOpen(false);
  };



  return (
    <div className="what_we_do">
      <MenuBar />

      {/* Banner Section */}
      <Container fluid>
        <Row>
          <div className="bannerArea animation">
            <img
              src={bannerImage}
              alt="Banner"
              className="w-100 banner-image svg"
            />
            <img src={dots} alt="Banner" className="dots left svg" />
            <img src={dots} alt="Banner" className="dots right svg" />
            <Container>
              <Row>
                <Col xs={12} md={6}>
                  <div className="banner-caption">
                    <p className="banner-text text-white mb-3">
                      Why do you need a team to evaluate your
                    </p>
                    <h1 className="banner-title banner-text">
                      <strong>Digital Assets?</strong>
                    </h1>
                    <p className="banner-text2 text-white mt-3">
                      As a team, Scrutinys have a predefined process to review
                    </p>
                    <p className="banner-title banner-text">
                      <RotatingText
                        className="rotate-text-what-we-do"
                        textArray={[
                          "Code Quality!",
                          "Team Efficiency!",
                          "Product Security!",
                        ]}
                      />
                    </p>
                  </div>
                </Col>
                <Col xs={12} md={5} className="relative">
                  <div className="pulse_button">
                    <div className="circle">
                      <div className="banner-action">
                        <a onClick={handleOpenPopupTalkExport}>
                          Get a Free Consultation
                        </a>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Row>
      </Container>
      {/* Banner Section */}

      {/* Process Section Start */}
      <Container className="process-section">
        <Row>
          <Col xs={12} md={6} lg={4} className="mb-5">
            <div className="process-box bg-1">
              <img src={quality} alt="" className="process-icon" />
              <div className="process-step">
                <h4>Code Quality Assurance</h4>
                <div className="step">
                  <strong>Maintainability</strong>
                  <p>
                    We ensure your codebase remains easy to manage and update,
                    reducing technical debt.
                  </p>
                </div>
                <div className="step">
                  <strong>Security</strong>
                  <p>
                    We fortify your software against vulnerabilities, keeping
                    your data and users safe.
                  </p>
                </div>
                <div className="step">
                  <strong>Extensibility</strong>
                  <p>
                    We empower your software to grow and adapt to changing
                    needs.
                  </p>
                </div>
              </div>
            </div>
          </Col>

          <Col xs={12} md={6} lg={4} className="mb-5">
            <div className="process-box bg-2">
              <img src={performance} alt="" className="process-icon" />
              <div className="process-step">
                <h4>Performance Optimization</h4>
                <div className="step">
                  <strong>Scalability</strong>
                  <p>
                    We optimize your software for scalability, ensuring it can
                    handle increased workloads.
                  </p>
                </div>
                <div className="step">
                  <strong>Process Automation</strong>
                  <p>
                    We implement automation solutions to streamline your
                    development processes.
                  </p>
                </div>
                <div className="step">
                  <strong>Cost Savings</strong>
                  <p>
                    Our strategies are designed to help you save costs without
                    compromising quality.
                  </p>
                </div>
              </div>
            </div>
          </Col>

          <Col xs={12} md={6} lg={4} className="banner-card mb-5">
            <div className="process-box bg-3">
              <img src={regulatory} alt="" className="process-icon" />
              <div className="process-step">
                <h4>Regulatory Compliance</h4>
                <div className="step">
                  <strong>Compliance Services</strong>
                  <p>
                    We specialize in regulatory standards, including HIPAA, SOC
                    2, GDPR Compliance, and more, to ensure your software meets
                    necessary regulations
                  </p>
                </div>
                <a
                  className="btn btn-primary"
                  onClick={handleOpenPopupEvaluation}
                >
                  Request for Evaluation
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {/* Process Section End */}

      {/* Hey Founder Section Start */}
      <Container fluid className="hey_founder">
        <Container className="founder_issue">
          <Row>
            <Col xs={12} md={6} className="desktopOnly">
              <div className="content-box">
                <div className="mb-5">
                  <h2 className="content-title mb-0">
                    Hey <b>Founder 👋</b>
                  </h2>
                  <p className="sub-title">
                    All this sounds so familiar, right?
                  </p>
                </div>
              </div>
              <img src={founder2} alt="" className="founder-thumb" />
            </Col>

            <Col xs={12} md={6}>
              <Row>
                <Col xs={12} md={6} className="mobileOnly">
                  <div className="content-box">
                    <div className="mb-5">
                      <h2 className="content-title mb-0">
                        Hey <b>Founder 👋</b>
                      </h2>
                      <p className="sub-title">
                        All this sounds so familiar, right?
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="box">
                    <p>
                      You are hesitant about your team&apos;s technical skills.
                    </p>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="box mt-4">
                    <p>You are worried about budget overruns.</p>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="box mt-4">
                    <p>You are doubtful about the product&apos;s tech stack.</p>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="box mt-4">
                    <p>You are experiencing delays on software launches.</p>
                  </div>
                </Col>
                <Col>
                  <div className="cta">
                    <p>
                      You deserve a <b> partner</b> who can handle all the
                      project&apos;s <b>tech tasks </b> and free you from
                      frustrations. Here&apos;s good news:{" "}
                      <b>you came to the right place</b> to get it.
                    </p>
                    <a
                      className="btn btn-primary btn-large"
                      onClick={handleOpenPopupTalkExport}
                    >
                      Let&apos;s Talk
                    </a>
                  </div>
                </Col>
                <Col xs={12} md={6} className="mobileOnly">
                  <img src={founder2} alt="" className="founder-thumb" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* Hey Founder Section End */}

      {/* Other Services Section Start */}
      <Container className="services-section">
        <Row>
          <Col xs={12}>
            <div className="content-box">
              <div className="text-center mb-5">
                <h2 className="content-title mb-0">
                  Other <b>Services</b>
                </h2>
                <img
                  src={border_center}
                  alt=""
                  className="title_border d-block mt-2 mb-2"
                />
              </div>
            </div>
          </Col>
          <Col xs={12} md={4} className="banner-card2 mb-5">
            <Row>
              <Col xs={12} md={12}>
                <div className="service-box s1">
                  <h5>Data Protection and Privacy:</h5>
                  <ul className="service-list">
                    <li>GDPR Compliance</li>
                    <li>CCPA Compliance</li>
                    <li>HIPAA Compliance</li>
                    <li>COPPA Compliance</li>
                    <li>Compliance with ECPA and Privacy Regulations</li>
                    <li>Financial and Business Standards</li>
                  </ul>
                </div>
              </Col>
              <Col xs={12} md={12}>
                <div className="service-box s2">
                  <h5>SOX Compliance</h5>
                  <ul className="service-list">
                    <li>FTC Regulatory Compliance</li>
                    <li>Online Advertising Practices</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>

          <Col xs={12} md={4} className="banner-card2 mb-5">
            <Row>
              <Col xs={12} md={12}>
                <div className="service-box s3">
                  <h5>DAA Self-Regulatory Principles </h5>
                  <ul className="service-list">
                    <li>Compliance</li>
                    <li>Payment Data Security:</li>
                  </ul>
                </div>
              </Col>
              <Col xs={12} md={12}>
                <div className="service-box s4">
                  <h5>CAN-SPAM Act Compliance</h5>
                  <ul className="service-list">
                    <li>Telemarketing Sales Rule (TSR) Compliance</li>
                    <li>Accessibility and Inclusivity:</li>
                  </ul>
                </div>
              </Col>
              <Col xs={12} md={12}>
                <div className="service-box s5">
                  <h5>PCI DSS Compliance</h5>
                  <ul className="service-list">
                    <li>Educational and Federal Compliance</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>

          <Col xs={12} md={4} className="banner-card2 mb-5">
            <Row>
              <Col xs={12} md={12}>
                <div className="service-box s6">
                  <h5>FERPA Compliance</h5>
                  <ul className="service-list">
                    <li>Compliance with Federal Regulations</li>
                    <li>Marketing and Communications</li>
                  </ul>
                </div>
              </Col>
              <Col xs={12} md={12}>
                <div className="service-box s7">
                  <h5>ADA Compliance</h5>
                  <ul className="service-list">
                    <li>WCAG Compliance</li>
                    <li>Intellectual Property and Content Protection:</li>
                  </ul>
                </div>
              </Col>
              <Col xs={12} md={12}>
                <div className="service-box s5">
                  <h5 className="mb-0">
                    Compliance with Intellectual Property Laws
                  </h5>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* Other Services Section End */}

      {/* Approach Section Start */}
      <ApproachSection />
      {/* Approach Section End */}

      {/* Facts Section Start */}
      <Container className="fact-section">
        <Row>
          <Col xs={12}>
            <div className="content-box">
              <div className="text-center mb-5">
                <h2 className="content-title mb-0">
                  Startup Failure Rate <b>Statistics</b>
                </h2>
                <img
                  src={border_center}
                  alt=""
                  className="title_border d-block mt-2 mb-2"
                />
              </div>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <Col xs={12} md={12}>
              <div className="fact-box">
                <img src={business} alt="" className="fact-icon" />
                <p>
                  <b>1 in 5 </b>businesses fold in <b>year one</b>
                </p>
              </div>
            </Col>
            <Col xs={12} md={12}>
              <div className="fact-box row-reverse">
                <img src={fifty} alt="" className="fact-icon" />
                <p>
                  <b>50% </b>fail by the four-year mark
                </p>
              </div>
            </Col>
            <Col xs={12} md={12}>
              <div className="fact-box">
                <img src={three_fourth} alt="" className="fact-icon" />
                <p>
                  <b>80% </b>last fewer than twenty years
                </p>
              </div>
            </Col>
          </Col>
          <Col xs={12} md={4}>
            <Col xs={12} md={12}>
              <div className="fact-box row-reverse">
                <img src={heart} alt="" className="fact-icon" />
                <p>
                  The healthcare and social assistance industry does the best
                  with <b> 56.9% </b>survival rates after <b>5 years</b>
                </p>
              </div>
            </Col>
            <Col xs={12} md={12}>
              <div className="fact-box">
                <img src={construction} alt="" className="fact-icon" />
                <p>
                  The construction industry does the worst with <b>40.8%</b>{" "}
                  survival rates after <b>5 years</b>
                </p>
              </div>
            </Col>
            <Col xs={12} md={12}>
              <div className="fact-box row-reverse">
                <img src={peaked} alt="" className="fact-icon" />
                <p>
                  The number of new businesses peaked in <b>2006</b> with{" "}
                  <b>715734</b> launched that year
                </p>
              </div>
            </Col>
          </Col>
          <Col xs={12} md={4}>
            <Col xs={12} md={12}>
              <div className="fact-box">
                <img src={rip} alt="" className="fact-icon" />
                <p>
                  <b>250000</b> businesses closed in the final quarter of 2008
                  due to the recession
                </p>
              </div>
            </Col>
            <Col xs={12} md={12}>
              <div className="fact-box row-reverse">
                <img src={close} alt="" className="fact-icon" />
                <p>
                  The pandemic forced <b> 25%</b> more businesses to close
                  between March 2020 and February 2021 than the typical average
                </p>
              </div>
            </Col>
          </Col>
        </Row>
      </Container>
      {/* Facts Section End */}

      {/* FAQ Section Start */}
      <Container className="faq-section">
        <Row>
          <Col xs={12} md={6}>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  How cyber security audit works?
                </Accordion.Header>
                <Accordion.Body>
                  Cybersecurity audits are comprehensive assessments of an
                  organization&apos;s information systems, policies, procedures,
                  and controls to identify and address potential security risks
                  and vulnerabilities.The goal of a cybersecurity audit is to
                  ensure that an organization&apos;s digital assets, data, and
                  systems are adequately protected against cyber threats.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Risk Assessment</Accordion.Header>
                <Accordion.Body>
                  Conduct a risk assessment to identify potential threats and
                  vulnerabilities that could impact the organization&apos;s
                  information systems and priorities the risks
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Compliance Review</Accordion.Header>
                <Accordion.Body>
                  Ensure that the organization complies with relevant
                  cybersecurity regulations, standards, and frameworks (e.g.,
                  GDPR, HIPAA, ISO 27001). Evaluate and adherence to its
                  internal cybersecurity policies and procedures.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Technical Assessment</Accordion.Header>
                <Accordion.Body>
                  Perform technical assessments to identify vulnerabilities in
                  systems, networks, and applications. Conduct penetration
                  testing to simulate cyber attacks and identify weaknesses in
                  security controls. Review configurations and settings for
                  proper alignment.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Access Controls Review</Accordion.Header>
                <Accordion.Body>
                  Assess user access controls, privileges, and authentication
                  mechanisms to ensure that only authorized individuals have
                  appropriate access to systems and data. Review IAM policies
                  and practices.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>Data Protection Evaluation</Accordion.Header>
                <Accordion.Body>
                  Examine data protection measures, including encryption, data
                  classification, and secure data handling practices, also
                  assess how sensitive information is stored, transmitted, and
                  processed.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col xs={12} md={6}>
            <div className="faq-heading">
              <h2 className="content-title mb-0 text-black">
                Have Any Questions? <br /> <b>Clear Your Doubts</b>{" "}
              </h2>
              <p className="text-black">
                We are the industry&apos;s tested and proven leaders who offer
                complete protection across critical areas of risk in your
                organization&apos;s digital infrastructure.
              </p>
              <a
                className="btn btn-primary"
                onClick={handleOpenPopupTalkExport}
              >
                Get Free Consultation
              </a>
            </div>
          </Col>
        </Row>
      </Container>
      {/* FAQ Section End */}

      <Footer />
      {isPopupEvaluationOpen && (
        <RequestEvaluation onClose={handleClosePopupEvaluation} />
      )}
      {isPopupTalkExportOpen && (
        <TalkExpert onClose={handleClosePopupTalkExport} />
      )}
    </div>
  );
};

export default WhatWeDo;
