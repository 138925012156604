import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  facebook,
  linkedin,
  twitter,
  instagram,
  logo,
  email,
  aws,
  google,
  adobe,
} from "../../assets";
import Career from "./Career";

const Footer = () => {
  const [isPopupCareerOpen, setPopupCareerOpen] = useState(false);
  const handleOpenPopupCareer = () => {
    setPopupCareerOpen(true);
  };
  const handleClosePopupCareer = () => {
    setPopupCareerOpen(false);
  };
  const year = new Date();
  let currentYear = year.getFullYear();
  return (
    <Container fluid className="footer">
      <Container className="widget-area">
        <Row>
          <Col xs={12} md={3}>
            <div className="footer-widget">
              <ul className="no-list logo-widget">
                <li>
                  <img src={logo} alt="" />
                  <div className="info email">
                    <img src={email} alt="" />
                    <a href="mailto:praveen@scrutinys.com">
                      <span>praveen@scrutinys.com</span>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className="footer-widget">
              <label>Follow Us</label>
              <ul className="no-list social-widget">
                <li>
                  <a href="https://www.facebook.com/scrutinys" target="_blank" rel="noreferrer">
                    <img src={facebook} alt="facebook" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/scrutinys"
                    target="_blank" rel="noreferrer"
                  >
                    <img src={linkedin} alt="linkedin" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/scrutinys_llp" target="_blank" rel="noreferrer">
                    <img src={twitter} alt="twitter" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/scrutinys.llp/"
                    target="_blank" rel="noreferrer"
                  >
                    <img src={instagram} alt="instagram" />
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className="footer-widget">
              <ul className="no-list partner-widget">
                <li>
                  <img src={aws} alt="" />
                </li>
                <li>
                  <img src={google} alt="" />
                </li>
                <li>
                  <img src={adobe} alt="" />
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className="footer-widget">
              <ul className="no-list link-widget">
                <li>
                  <a href="/blog">Blog</a>
                </li>
                <li>
                  <Link onClick={handleOpenPopupCareer}>Career</Link>
                </li>
                {isPopupCareerOpen && (
                  <Career onClose={handleClosePopupCareer} />
                )}
                <li>
                  <Link to={`/privacy-policy`}>Privacy Policy</Link>
                </li>
                <li>
                  <Link to={`/terms-and-conditions`}>Terms & Conditions</Link>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className="copy-right">
          <Col xs={12} md={5}>
            <a
              href="//www.dmca.com/Protection/Status.aspx?ID=dea4fa2f-77eb-4d64-b0bb-2065f182ef78"
              title="DMCA.com Protection Status"
              className="dmca-badge"
              target="_blank" rel="noreferrer"
            >
              {" "}
              <img
                src="https://images.dmca.com/Badges/dmca-badge-w250-5x1-06.png?ID=dea4fa2f-77eb-4d64-b0bb-2065f182ef78"
                width={150}
                alt="DMCA.com Protection Status"
              />
            </a>
          </Col>
          <Col xs={12} md={7}>
            <small>
              Copyright © {currentYear} Scrutinys, All Rights Reserved | Sitemap
            </small>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
