import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Home from './components/Home';
import WhatWeDo from './components/WhatWeDo';
import TnC from './components/TnC';
import NotFound from './components/NotFound';
import PrivacyPolicy from './components/PrivacyPolicy';
import ReplaceSvg from './components/comman/ReplaceSvg';
import SoftwareCodeAuditServices  from  './components/Software-Code-Audit-Services'

const ScrollToTop = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const App = () => {
  return (
    <>
    <Router>
      <ScrollToTop />
      <ReplaceSvg />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/what-we-do" element={<WhatWeDo />} />
        <Route path="/software-code-audit-services" element={< SoftwareCodeAuditServices/>} />
        <Route path="/terms-and-conditions" element={<TnC />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
    </>
  );
};

export default App;