// import {navLinks} from "../assets";

export const googleReCaptchaKey = '6LeauZ8pAAAAAANapwxdM1cXrRXivDqh52Q4i0r3';

export const navLinks = [
  {
    id: "what-we-do",
    title: "What We Bring?",
  },
];
export const bannerHeader = [
  {
    id: "reviewer",
    subtitle: "This is all about Trust!",
    title:"Our",
    titleBold: "Reviewers",
  },
  {
    id: "recommended",
    subtitle: "Checkout Our",
    title:"Recommended",
    titleBold: "Trips",
  },
  {
    id: "custom",
    subtitle: "Detail Plan for",
    title:"Trip",
    titleBold: "Days",
  },
];