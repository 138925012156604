import axios from 'axios';

const SendEmail = async (formData) => {
    try {
        // const updateFormData = {}
        // for (let key in formData) {
        //     if (formData[key] !== '') {
        //         updateFormData[key] = formData[key]
        //     }
        // }

        const response = await axios.post('https://api.scrutinys.com/sendEmail', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }});
       
        return response;
    } catch (error) {
        console.error(error);
        return { data: false };
    }
};



export default SendEmail;