import React, { useRef, useState } from "react";
import { Form, Modal, Row, Col } from "react-bootstrap";
import ReCAPTCHA from 'react-google-recaptcha';
import { expert, dots } from "../../assets";
import SendEmail from "../../apies/sendEmail";
import { toast } from "react-toastify";
import { googleReCaptchaKey } from "../../constants";
const initialFormData = {
  fullName: "",
  email: "",
  contactNumber: "",
};
const TalkExpert = (props) => {
  const [formData, setFormData] = useState(initialFormData);
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [, setIsCaptchaSuccessful] = React.useState(false);
  const recaptchaRef = useRef(null);

  function onChange(value) {
    setIsCaptchaSuccessful(true);
    console.log("captcha value:", value);
  }

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const captchaValue = recaptchaRef.current.getValue();
    if (formData.contactNumber.length > 15) {
      toast.error("The contact number must be less than 15 characters");
      return;
    } else if (!emailPattern.test(formData.email)) {
      toast.error("Please enter a valid email address");
      return;
    } else if (!captchaValue) {
      toast.error('Please verify that you are not a robot.');
      return;
    }

    setIsLoading(true);
    const data = new FormData();
    const html = `<p>A new request for Talk has been received:</p>
        <p><strong>Full Name:</strong> ${formData.fullName}</p>
        <p><strong>Email:</strong> ${formData.email}</p>
        <p><strong>Contact Number:</strong> ${formData.contactNumber}</p>
        <p><strong>isMutual:</strong> ${isChecked ? "Yes" : "No"}</p>`;
    const subject = "New Submission to request for Talk";
    const heading = `Request for Talk`;
    data.append("email", formData.email);
    data.append("subject", subject);
    data.append("heading", heading);
    data.append("htmlContent", html);
    SendEmail(data).then((response) => {
      // console.log(response.data);
      setIsLoading(false); // hide loader

      if (response.data.status) {
        // alert(response.data.message);
        toast.success(response.data.message);
        setFormData(initialFormData);
      } else {
        setFormData(initialFormData);

        const errorText =
          response.data?.data?.details.length > 0
            ? response.data.data.details[0].message
            : response.data.message;
        toast.error(errorText);
      }
      recaptchaRef.current.reset();
    });
  };
  const isSubmit =
    formData.fullName && formData.email && formData.contactNumber;
  return (
    <>
      <Modal
        show={true}
        // eslint-disable-next-line react/prop-types
        onHide={props.onClose}
        dialogClassName="modal-90w"
        aria-labelledby="modal-title"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img src={dots} alt="Banner" className="dots top-right" />
          <img src={dots} alt="Banner" className="dots left-bottom" />
          <Row>
            <Col xs={12} md={6}>
              <div className="popup-header">
                <p className="sub-title">Have a query?</p>
                <h2>
                  Let’s <b>Talk</b> <br /> to our <b> Expert</b>
                </h2>
                <p className="help-text">
                  Fill out this form and you will receive a call back from our
                  experts within 15 minutes.
                </p>
              </div>
              <div className="popup-content">
                <p className="mt-4">
                  {" "}
                  <strong> All project secured by NDA & IP is your’s </strong>
                </p>
                <p className="mt-4">
                  <strong> 100% Secure. Zero Spam. </strong>
                </p>
                <img src={expert} alt="" className="expert-image" />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <form action="#" className="form">
                <h3 className="hand-icon">👋</h3>
                <div className="form-group">
                  <label htmlFor="fullName">
                    Your Name <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    type="text"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Full name"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">
                    Email <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Ex.: test@example.com"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="contactNumber">
                    Contact Number <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    type="number"
                    name="contactNumber"
                    className="form-control"
                    placeholder="Ex.: 9887469725"
                    value={formData.contactNumber}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <Form.Check
                    inline
                    label="Yes, send me a mutual NDA (Non-Disclosure
                    Agreement)"
                    id="checkbox_id"
                    htmlFor="checkbox_id"
                    name="group1"
                    type={"checkbox"}
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                </div>
                <div className="form-group">
                <ReCAPTCHA
                          ref={recaptchaRef}
                          sitekey={googleReCaptchaKey}
                          onChange={onChange}
                      />
                </div>

                <div className="form-group  mb-0">
                  {isLoading ? (
                    <span className="btn loader float-end d-flex "></span>
                  ) : (
                    <input
                      type="Submit"
                      className="btn btn-submit"
                      value="Schedule a call"
                      disabled={!isSubmit} // Disable button if form is not valid or if it's loading
                      onClick={onSubmit}
                    />
                  )}
                </div>
              </form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TalkExpert;
