import React from "react";
import MenuBar from "./comman/MenuBar";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { dots } from "../assets";
import bannerImage from "../assets/banner1.webp";

const NotFound = () => {
  return (
    <div className="404">
      <MenuBar />

      {/* 404 Section */}
      <Container fluid>
        <Row>
          <div className="bannerArea animation">
            <img
              src={bannerImage}
              alt="Banner"
              className="w-100 banner-image svg"
            />
            <img src={dots} alt="Banner" className="dots left svg" />
            <img src={dots} alt="Banner" className="dots right svg" />
          </div>
          <Container>
            <Row>
              <Col xs={12} md={12}>
                <div className="notFoundCaption">
                  <div className="Square">
                    <h1>404</h1>
                  </div>

                  <div className="texts">
                    <h3>Oops! page not found</h3>
                    <p>
                      The page you are looking for does not exist. Go back to
                      the main
                    </p>
                    <Link to="/" className="btn btn-primary">
                      Back to Home
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
      {/* 404 Section */}

      {/* <Footer /> */}
    </div>
  );
};

export default NotFound;
