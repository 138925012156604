import React, { useState, useEffect, useRef } from "react";
import MenuBar from "./comman/MenuBar";
import Footer from "./comman/Footer";
import TalkExpert from "./comman/TalkExpert";
import RotatingText from "./comman/RotatingText";

import { Container, Row, Col, Form } from "react-bootstrap";
import {
  cta,
  auditprocess,
  codeauditreport,
  codeauditreportmobile,
  border_center,
  dots,
  quality,
  regulatory,
  performance,
  border_left,
  founder1,
  enhanced_security,
  improved_performance,
  compliance_assurance,
  increased_code_quality,
  cost_savings,
  data_protection_evaluation,
  strategic_insights,
  projectsetup,
  arrow,
  arrowdown,
  researchconcept,
  wireframing,
  testingvalidation,
  boxframe,
  btnenhancedsoftwaresecurity,
  expert_audit_services_icon,
  comprehensive_code_audits_icon,
  performance_optimization_icon,
} from "../assets";
import Accordion from "react-bootstrap/Accordion";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import SendEmail from "../apies/sendEmail";
import { googleReCaptchaKey } from "../constants";
import WOW from "wowjs";

import "../styles/loader.css";

import CountUp from "react-countup";
import bannerImage from "../assets/banner1.webp";

function isInView(element, inViewType) {
  const viewport = {};
  viewport.top = window.scrollY;
  viewport.bottom = viewport.top + window.innerHeight;
  const bounds = {};
  bounds.top = element.offsetTop;
  bounds.bottom = bounds.top + element.offsetHeight;
  
  switch (inViewType) {
    case "bottomOnly":
      return bounds.bottom <= viewport.bottom && bounds.bottom >= viewport.top;
    case "topOnly":
      return bounds.top <= viewport.bottom && bounds.top >= viewport.top;
    case "both":
      return bounds.top >= viewport.top && bounds.bottom <= viewport.bottom;
    default:
      return bounds.top >= viewport.top && bounds.bottom <= viewport.bottom;
  }
}

const Home = () => {
  const sectionRef = useRef(null);
  const sectionRef2 = useRef(null);
  const [isPopupTalkExportOpen, setPopupTalkExportOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleOpenPopupTalkExport = () => {
    setPopupTalkExportOpen(true);
  };
  const handleClosePopupTalkExport = () => {
    setPopupTalkExportOpen(false);
  };

  const [, setIsCaptchaSuccessful] = React.useState(false);
  const recaptchaRef = useRef(null);

  function onChange(value) {
    setIsCaptchaSuccessful(true);
    console.log("captcha value:", value);
  }
  // here we handle form data
  const initialFormData = {
    fullName: "",
    email: "",
    contactNumber: "",
    gitRepoLink: "",
    websiteUrl: "",
    date: new Date().toISOString().split("T")[0], // current date in YYYY-MM-DD format
    time: new Date().toLocaleTimeString("it-IT").slice(0, 5), // current time in HH:MM format
  };
  const [formData, setFormData] = useState(initialFormData);
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const captchaValue = recaptchaRef.current.getValue();
    /*  if (!isChecked) {
            toast.error("Please agree to the terms and conditions");
            return;
        } else  */
    if (formData.contactNumber.length > 15) {
      toast.error("The contact number must be less than 15 characters");
      return;
    } else if (!emailPattern.test(formData.email)) {
      toast.error("Please enter a valid email address");
      return;
    } else if (!captchaValue) {
      toast.error("Please verify that you are not a robot.");
      return;
    }
    setIsLoading(true); // show loader
    const data = new FormData();
    const html = `<p>A new request for evaluation has been received:</p>
          <p><strong>Full Name:</strong> ${formData.fullName}</p>
          <p><strong>Email:</strong> ${formData.email}</p>
          <p><strong>Contact Number:</strong> ${formData.contactNumber}</p>
          <p><strong>Git Repository Link:</strong> <a href="${
            formData.gitRepoLink
          }">${formData.gitRepoLink}</a></p>
          <p><strong>Website URL:</strong> <a href="${formData.websiteUrl}">${
      formData.websiteUrl
    }</a></p>
          <p><strong>Date:</strong> ${formData.date}</p>
          <p><strong>Time:</strong> ${formData.time}</p>
          <p><strong>isMutual:</strong> ${isChecked ? "Yes" : "No"}</p>`;
    const subject = "New Submission to request for evaluation";
    const heading = "Request for Evaluation";
    data.append("email", formData.email);
    data.append("subject", subject);
    data.append("heading", heading);
    data.append("htmlContent", html);
    SendEmail(data).then((response) => {
      // console.log(response.data);
      setIsLoading(false); // hide loader

      if (response.data.status) {
        // alert(response.data.message);
        toast.success(response.data.message);
        setFormData(initialFormData);
      } else {
        setFormData(initialFormData);
        const errorText =
          response.data?.data?.details.length > 0
            ? response.data.data.details[0].message
            : response.data.message;
        toast.error(errorText);
      }
      recaptchaRef.current.reset();
    });
  };

  const accordionData = [
    {
      title: "Enhanced Security",
      text: "Identify and resolve vulnerabilities before they can be exploited, ensuring your software is protected against potential threats and attacks.",
      image: enhanced_security,
      indexnumber: "01",
    },
    {
      title: "Improved Performance",
      text: "Uncover and address performance bottlenecks, leading to faster and more efficient software that provides a better user experience.",
      image: improved_performance,
      indexnumber: "02",
    },
    {
      title: "Compliance Assurance",
      text: "Ensure your software meets all relevant industry regulations and standards, reducing the risk of legal issues and penalties.",
      image: compliance_assurance,
      indexnumber: "03",
    },
    {
      title: "Increased Code Quality",
      text: "Enhance the readability, maintainability, and overall quality of your code, making it easier for developers to work with and extend.",
      image: increased_code_quality,
      indexnumber: "04",
    },
    {
      title: "Cost Savings",
      text: "Avoid costly issues by addressing potential problems early, saving on future repair costs and minimizing disruptions to your development process.",
      image: cost_savings,
      indexnumber: "05",
    },
    {
      title: "Data Protection Evaluation",
      text: "Examine data protection measures, including encryption, data classification, and secure data handling practices. Assess how sensitive information is stored, transmitted, and processed.",
      image: data_protection_evaluation,
      indexnumber: "06",
    },
    {
      title: "Strategic Insights",
      text: "Gain actionable recommendations and insights from our expert auditors to guide your development strategy and improve your software’s long-term success.",
      image: strategic_insights,
      indexnumber: "07",
    },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 } // Adjust the threshold based on your needs
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, [sectionRef]);

  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // Check if the top of the section is at the top of the viewport
          //console.log(entry.boundingClientRect.bottom +'===='+ window.innerHeight);

          if (entry.boundingClientRect.top <= 0 && entry.isIntersecting) {
            setIsVisible2(true);
          } // Check if the bottom of the section is out of view
          else if (entry.boundingClientRect.bottom <= 0) {
            setIsVisible2(false); // Remove 'in-view' class when section bottom leaves the viewport
          } else {
            setIsVisible2(false); // Remove 'in-view' class if not visible
          }
        });
      },
      {
        root: null, // relative to the viewport
        rootMargin: "0px 0px -100% 0px", // Trigger when the top of the section reaches the top of the viewport
        threshold: 0, // Set threshold to 0 to trigger as soon as the top of the element hits the top of the viewport
      }
    );

    if (sectionRef2.current) {
      observer.observe(sectionRef2.current);
    }

    return () => {
      if (sectionRef2.current) {
        observer.unobserve(sectionRef2.current);
      }
    };
  }, [setIsVisible2]);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)"); // Define mobile screen width (768px is commonly used as a breakpoint)

    // Update state based on the initial screen size
    setIsMobile(mediaQuery.matches);

    // Define a function to handle screen width changes
    const handleResize = (e) => {
      setIsMobile(e.matches);
    };

    // Add a listener for screen width changes
    mediaQuery.addEventListener("change", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      mediaQuery.removeEventListener("change", handleResize);
    };
  }, []);

  const [auditingServicesInView, setAuditingServicesInView] = useState(false);
  const [auditingServicesInView2, setAuditingServicesInView2] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const auditingServicesElement = document.getElementById("auditing-services");
      if (auditingServicesElement) {
        setAuditingServicesInView(isInView(auditingServicesElement, "topOnly"));
      }
      
      //const servicessection = document.getElementById("services-section");
     // console.log(servicessection.offsetHeight, '====', window.scrollY)

      
      const auditingservicesbox = document.getElementById("auditingservicesbox");
      if (auditingservicesbox) {
        setAuditingServicesInView2(isInView(auditingServicesElement, "bottomOnly"));
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isSubmit =
    formData.fullName && formData.email && formData.contactNumber;
  return (
    <div className="software-code-audit-services">
      <MenuBar />

      {/* Banner Section */}
      <Container fluid>
        <Row>
          <div className="bannerArea animation softwareCodeBanner">
            <img
              src={bannerImage}
              alt="Banner"
              className="w-100 banner-image svg"
            />
            <img src={dots} alt="Banner" className="dots left svg" />
            <img src={dots} alt="Banner" className="dots right svg" />
            <Container>
              <Row>
                <Col xs={12} md={7}>
                  <div className="banner-caption">
                    <h1 className="banner-title animated-text fadeInDown">
                      Evaluate Your <br className="hide-br" />{" "}
                      <RotatingText
                        className="rotate-text-software"
                        textArray={[
                          "Comprehensive Code Audits",
                          "Enhanced Software Security",
                          "Performance Optimization",
                          "Expert Audit Services",
                        ]}
                      />
                      <br /> with Scrutinys
                    </h1>
                    <p className="text-white mt-3 mb-5 paraFor">
                      Our expert Software Code auditors will thoroughly review
                      your software to identify vulnerabilities, optimize
                      performance, and ensure compliance with industry
                      standards. As a leading Software Code Auditing Company,
                      Scrutinys is here to help you.
                    </p>
                  </div>
                </Col>
                <Col xs={12} md={5} className="relative">
                  <div className="pulse_button">
                    <div className="circle">
                      <div className="banner-action">
                        <a onClick={handleOpenPopupTalkExport}>
                          Get a Free Consultation
                        </a>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>

            <Container className="box-container">
              <Row>
                <ul className="d-flex justify-content-between info-boxex">
                  <li>
                    <div className="frame relative">
                      <img
                        className="frame-image"
                        src={boxframe}
                        alt="box-frame"
                      />
                      <img
                        className="frame-icon"
                        src={comprehensive_code_audits_icon}
                        alt="box-image"
                      />
                    </div>
                    <div className="box-text">Comprehensive Code Audits</div>
                  </li>
                  <li>
                    <div className="frame relative">
                      <img
                        className="frame-image"
                        src={boxframe}
                        alt="box-frame"
                      />
                      <img
                        className="frame-icon"
                        src={btnenhancedsoftwaresecurity}
                        alt="box-image"
                      />
                    </div>
                    <div className="box-text">Enhanced Software Security</div>
                  </li>
                  <li>
                    <div className="frame relative">
                      <img
                        className="frame-image"
                        src={boxframe}
                        alt="box-frame"
                      />
                      <img
                        className="frame-icon"
                        src={performance_optimization_icon}
                        alt="box-image"
                      />
                    </div>
                    <div className="box-text">Performance Optimization</div>
                  </li>
                  <li>
                    <div className="frame relative">
                      <img
                        className="frame-image"
                        src={boxframe}
                        alt="box-frame"
                      />
                      <img
                        className="frame-icon"
                        src={expert_audit_services_icon}
                        alt="box-image"
                      />
                    </div>
                    <div className="box-text">Expert Audit Services</div>
                  </li>
                </ul>
              </Row>
            </Container>
          </div>
        </Row>
      </Container>
      {/* Banner Section */}

      {/* Process Section Start */}
      <Container className="process-section">
        <Row>
          <Col xs={12}>
            <div className="content-box">
              <div className="text-center mb-5">
                <h2 className="content-title mb-2">
                  Our Comprehensive Software Audit Services
                </h2>
                <p>
                  Ensure your software’s performance, security, and scalability
                  with our expert Software Audit services.
                </p>
                <img
                  src={border_center}
                  alt=""
                  className="title_border d-block mt-2 mb-2"
                />
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} lg={4} className="mt-5">
            <div className="process-box bg-1">
              <img src={quality} alt="" className="process-icon" />
              <div className="process-step">
                <h4>Security Issue Identification</h4>
                <p>
                  Our Code Audit experts conduct a meticulous review of your
                  software to identify and address security vulnerabilities that
                  could expose your system to threats.
                </p>
                <div className="step">
                  <strong>Vulnerability Detection</strong>
                  <p>
                    We scan your codebase to uncover potential security gaps and
                    flaws.
                  </p>
                </div>
                <div className="step">
                  <strong>Threat Assessment</strong>
                  <p>
                    Our team evaluates the identified risks to understand their
                    impact on your system.
                  </p>
                </div>
                <div className="step">
                  <strong>Remediation Planning</strong>
                  <p>
                    We provide clear, actionable steps to mitigate the risks and
                    secure your software against future threats.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} lg={4} className="mt-5">
            <div className="process-box bg-2">
              <img src={performance} alt="" className="process-icon" />
              <div className="process-step">
                <h4>Bottleneck Detection</h4>
                <p>
                  As Code audit consultants, we analyze your software’s
                  performance to identify and eliminate bottlenecks that slow
                  down your system and hinder user experience.
                </p>
                <div className="step">
                  <strong>Software code analysis for performance</strong>
                  <p>
                    Using advanced tools, we assess the performance of various
                    software components to find areas where it lags.
                  </p>
                </div>
                <div className="step">
                  <strong>Efficiency Optimization</strong>
                  <p>
                    Our experts suggest code and architectural improvements to
                    enhance speed and responsiveness.
                  </p>
                </div>
                <div className="step">
                  <strong>Load Testing</strong>
                  <p>
                    We simulate high-traffic scenarios to ensure your software
                    can maintain performance under stress.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} lg={4} className="banner-card mt-5">
            <div className="process-box bg-3">
              <img src={regulatory} alt="" className="process-icon" />
              <div className="process-step">
                <h4>App Architecture Validation</h4>
                <p>
                  Being a leading Code Audit company, evaluate your software’s
                  architecture to ensure it is robust, scalable, and adheres to
                  industry best practices, paving the way for future growth.
                </p>
                <div className="step">
                  <strong>Structural Analysis</strong>
                  <p>
                    We review the design and organization of your software’s
                    components to identify potential weaknesses.
                  </p>
                </div>
                <div className="step">
                  <strong>Scalability Review</strong>
                  <p>
                    Our team assesses whether your architecture can handle
                    increased loads as your user base grows.
                  </p>
                </div>
                <div className="step">
                  <strong>Best Practices Check</strong>
                  <p>
                    We ensure that your architecture aligns with industry
                    standards and modern design principles.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} lg={4} className="banner-card mt-5">
            <div className="process-box bg-1">
              <img src={quality} alt="" className="process-icon" />
              <div className="process-step">
                <h4>DevOps Processes Audit</h4>
                <p>
                  Our Software code audit focuses on refining your DevOps
                  processes, ensuring seamless collaboration between development
                  and operations for faster, more reliable software delivery.
                </p>
                <div className="step">
                  <strong>Pipeline Optimization</strong>
                  <p>
                    We streamline your CI/CD pipelines to make your development
                    and deployment processes more efficient.
                  </p>
                </div>
                <div className="step">
                  <strong>Process Efficiency</strong>
                  <p>
                    identifying bottlenecks and redundancies, we enhance the
                    speed and reliability of your DevOps workflow.
                  </p>
                </div>
                <div className="step">
                  <strong>Continuous Integration Review</strong>
                  <p>
                    We ensure your integration practices are robust and
                    effective, reducing the risk of errors and downtime.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} lg={4} className="banner-card mt-5">
            <div className="process-box bg-2">
              <img src={performance} alt="" className="process-icon" />
              <div className="process-step">
                <h4>Code Quality Assessment</h4>
                <p>
                  We conduct a thorough assessment of your codebase, focusing on
                  maintainability, readability, and overall code quality to
                  ensure long-term sustainability.
                </p>
                <div className="step">
                  <strong>Code Readability</strong>
                  <p>
                    We assess how easy it is for developers to understand and
                    work with your code, recommending improvements where needed.
                  </p>
                </div>
                <div className="step">
                  <strong>Error Detection</strong>
                  <p>
                    Our experts identify coding errors, bugs, and other issues
                    that could impact software functionality.
                  </p>
                </div>
                <div className="step">
                  <strong>Refactoring Recommendations</strong>
                  <p>
                    We provide guidance on restructuring your code for better
                    efficiency and maintainability.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} lg={4} className="banner-card mt-5">
            <div className="process-box bg-3">
              <img src={regulatory} alt="" className="process-icon" />
              <div className="process-step">
                <h4>Compliance Audit</h4>
                <p>
                  Source code audit services ensure that your software complies
                  with industry-specific regulations and standards, reducing the
                  risk of legal issues and penalties.
                </p>
                <div className="step">
                  <strong>Regulatory Alignment</strong>
                  <p>
                    We review your software against relevant industry
                    regulations, ensuring it meets all necessary requirements.
                  </p>
                </div>
                <div className="step">
                  <strong>Data Protection</strong>
                  <p>
                    Our audit assesses how well your software handles and
                    protects sensitive user data, including encryption and
                    storage practices.
                  </p>
                </div>
                <div className="step">
                  <strong>Audit Reporting</strong>
                  <p>
                    We deliver a comprehensive report outlining compliance
                    status, areas of concern, and recommendations for
                    improvement.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} lg={4} className="banner-card mt-5">
            <div className="process-box bg-1">
              <img src={quality} alt="" className="process-icon" />
              <div className="process-step">
                <h4>Source Code Documentation Review</h4>
                <p>
                  We audit your source code documentation to ensure it is
                  thorough, up-to-date, and useful for current and future
                  developers working on your project.
                </p>
                <div className="step">
                  <strong>Documentation Completeness</strong>
                  <p>
                    We evaluate the comprehensiveness of your existing
                    documentation, ensuring it covers all critical aspects of
                    the codebase.
                  </p>
                </div>
                <div className="step">
                  <strong>Consistency Check</strong>
                  <p>
                    Our team checks that your documentation accurately reflects
                    the current state of the code, including any recent changes
                    or updates.
                  </p>
                </div>
                <div className="step">
                  <strong>Improvement Suggestions</strong>
                  <p>
                    We provide actionable recommendations to enhance the clarity
                    and utility of your documentation.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} lg={4} className="banner-card mt-5">
            <div className="process-box bg-2">
              <img src={performance} alt="" className="process-icon" />
              <div className="process-step">
                <h4>Automated Software Audit</h4>
                <p>
                  In Automated software audit utilizing automated tools, we
                  conduct a rigorous and efficient audit of your codebase,
                  offering a faster and more cost-effective way to maintain high
                  standards of software quality.
                </p>
                <div className="step">
                  <strong>Tool Integration</strong>
                  <p>
                    We implement state-of-the-art automated auditing tools to
                    continuously monitor your code for issues.
                  </p>
                </div>
                <div className="step">
                  <strong>Comprehensive Coverage</strong>
                  <p>
                    Automated processes ensure that every line of code is
                    thoroughly examined, catching errors that manual reviews
                    might miss.
                  </p>
                </div>
                <div className="step">
                  <strong>Efficiency Gains</strong>
                  <p>
                    Automation allows us to conduct frequent audits with minimal
                    disruption to your development process, ensuring ongoing
                    quality and security.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} lg={4} className="banner-card mt-5">
            <div className="process-box bg-3">
              <img src={regulatory} alt="" className="process-icon" />
              <div className="process-step">
                <h4>Code Security Audit</h4>
                <p>
                  Our in-depth code security audit is designed to uncover hidden
                  vulnerabilities, ensuring that your software is secure against
                  cyber threats and compliant with security standards.
                </p>
                <div className="step">
                  <strong>Threat Identification</strong>
                  <p>
                    We perform a detailed analysis to identify potential
                    security risks within your code.
                  </p>
                </div>
                <div className="step">
                  <strong>Mitigation Strategies</strong>
                  <p>
                    Our experts develop tailored strategies to address and
                    mitigate identified threats, enhancing your software’s
                    security posture.
                  </p>
                </div>
                <div className="step">
                  <strong>Ongoing Monitoring</strong>
                  <p>
                    We recommend and implement tools for continuous security
                    monitoring, helping to prevent future vulnerabilities from
                    arising.
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {/* Process Section End */}

      {/* Startups Breakdown? Start */}
      <Container className="startup-section software-startup-section">
        <a className="float-btn" onClick={handleOpenPopupTalkExport}>
          Get Free Consultation
        </a>
        <Row>
          <Col xs={12} md={7}>
            <div className="content-box">
              <div className="mb-5">
                <h2 className="content-title mb-0 text-black">
                  When Do You Need a <br />{" "}
                  <b className="text-black">Code Audit?</b>{" "}
                </h2>
                <img src={border_left} alt="" className="mt-2 mb-2" />
                <p className="text-black">
                  A Code auditing service becomes essential when your software
                  starts showing performance issues, security vulnerabilities,
                  or scalability concerns. It’s also crucial after significant
                  updates or before major releases to ensure code quality and
                  compliance with industry standards. If you’re integrating new
                  technologies or migrating to a new platform, a thorough code
                  audit can prevent potential risks. Regular Software audit are
                  key to maintaining the long-term health, security, and
                  performance of your software.
                </p>
              </div>
              <Row>
                <Col xs={12} md={12}>
                  <div className="s1">
                    <ul className="service-list">
                      <li>Security Breaches & Data Leaks</li>
                      <li>Regulatory Changes</li>
                      <li>Tech Stack Upgrades</li>
                      <li>Significant Growth</li>
                      <li>Source code audit</li>
                      <li>Before or After Mergers and Acquisitions</li>
                      <li>Launching MVPs</li>
                      <li>QA Audit</li>
                      <li>Ongoing Project Reviews</li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={12} md={5}>
            <div className="rocket_thumb software-startup-img">
              <img src={founder1} alt="" />
            </div>
            <div className="mobileOnly">
              <img src={founder1} alt="" />
            </div>
          </Col>
        </Row>
      </Container>
      {/* Startups Breakdown? End */}

      {/*  Cta Section Start   */}
      <Container fluid className="software-code-cta-section">
        <Container>
          <Row id="software-code-cta-section-row">
            <Col xs={12} md={6} className="relative">
              <p className="text-white mt-3 paraFor">
                Don&apos;t wait for issues to escalate
              </p>

              <h2 className="mb-0 display-5 text-uppercase text-white fw-bold">
                Secure your software’s future
              </h2>
              <p className="text-white mt-3 mb-5 paraFor">
                with a comprehensive code audit today.
              </p>
              <div className="btnanimation">
                <a
                  className="btn btn-light textsuccess"
                  onClick={handleOpenPopupTalkExport}
                >
                  Request your code audit
                </a>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <img
                src={cta}
                alt=""
                className="title_border d-block mt-2 mb-2"
              />
            </Col>
          </Row>
        </Container>
      </Container>
      {/*  CTA Section End   */}

      {/*  Process for code Section Start   */}
      <section className="section our-process-section" id="id53e4887a">
        <div className="container">
          <h2>Process of Code Audit</h2>
          <div className="editor-content col-lg-7 p-0 col-md-12">
            We conduct a comprehensive and in-depth code analysis of your source
            code. Using our reports, you will gain valuable insights into your
            product in order to make well-informed decisions. Here is how our
            process works:
          </div>
        </div>
        <div className="text-content">
          <div className="wrap-content">
            <div className="wrap container">
              <div
                className="item wow fadeIn"
                data-wow-delay="0.05s"
                style={{ visibility: "visible", animationDelay: "0.05s" }}
              >
                <div className="circle-process">
                  <img src={projectsetup} alt="project-setup" />
                </div>
                <span>Code Audit Request</span>
              </div>
              <div
                className="arrow item wow fadeIn"
                data-wow-delay=".75s"
                style={{ visibility: "visible", animationDelay: "0.75s" }}
              >
                <img className="desktop" src={arrow} alt="arrow" />
                <img className="mobile" src={arrowdown} alt="arrow" />
              </div>
              <div
                className="item wow fadeIn"
                data-wow-delay="0.15s"
                style={{ visibility: "visible", animationDelay: "0.15s" }}
              >
                <div className="circle-process">
                  <img src={researchconcept} alt="research-concept" />
                </div>
                <span>MNDA Signing</span>
              </div>
              <div
                className="arrow item wow fadeIn"
                data-wow-delay=".75s"
                style={{ visibility: "visible", animationDelay: "0.75s" }}
              >
                <img className="desktop" src={arrow} alt="arrow" />
                <img className="mobile" src={arrowdown} alt="arrow" />
              </div>
              <div
                className="item wow fadeIn"
                data-wow-delay="0.25s"
                style={{ visibility: "visible", animationDelay: "0.25s" }}
              >
                <div className="circle-process">
                  <img src={wireframing} alt="wireframing" />
                </div>
                <span>Introduction Interview</span>
              </div>
              <div
                className="arrow item wow fadeIn"
                data-wow-delay=".75s"
                style={{ visibility: "visible", animationDelay: "0.75s" }}
              >
                <img className="desktop" src={arrow} alt="arrow" />
                <img className="mobile" src={arrowdown} alt="arrow" />
              </div>
              <div
                className="item wow fadeIn"
                data-wow-delay="0.35s"
                style={{ visibility: "visible", animationDelay: "0.35s" }}
              >
                <div className="circle-process">
                  <img src={testingvalidation} alt="testing-validation" />
                </div>
                <span>Code Repository Access</span>
              </div>
              <div
                className="arrow item wow fadeIn"
                data-wow-delay=".75s"
                style={{ visibility: "visible", animationDelay: "0.75s" }}
              >
                <img className="desktop" src={arrow} alt="arrow" />
                <img className="mobile" src={arrowdown} alt="arrow" />
              </div>
              <div
                className="item wow fadeIn"
                data-wow-delay="0.45s"
                style={{ visibility: "visible", animationDelay: "0.45s" }}
              >
                <div className="circle-process">
                  <img src={auditprocess} alt="visual-design" />
                </div>
                <span>Audit Process</span>
              </div>
              <div
                className="arrow item wow fadeIn"
                data-wow-delay=".75s"
                style={{ visibility: "visible", animationDelay: "0.75s" }}
              >
                <img className="desktop" src={arrow} alt="arrow" />
                <img className="mobile" src={arrowdown} alt="arrow" />
              </div>
              <div className="item mobile">
                <div className="text-block">
                  <span>Code Audit Report</span>
                </div>
                <img src={codeauditreportmobile} alt="group-9" />
              </div>
            </div>
            <div
              className="wrap-image wow animated pulse"
              data-wow-iteration="infinite"
              data-wow-duration="1.5s"
              style={{
                visibility: "visible",
                animationDuration: "1.5s",
                animationIterationCount: "infinite",
              }}
            >
              <div className="text-block">
                <span>Code Audit Report</span>
              </div>
              <img src={codeauditreport} alt="development-handover-1" />
            </div>
          </div>
        </div>
      </section>
      {/*  Process for code Section End   */}

      {/* Exclusive Section Start */}
      <Container
        fluid
        className="software-code-exclusive-section"
        id={isVisible2 && auditingServicesInView2 == '' ? "in-view" : ""}
        ref={sectionRef2}
      >
        <Container className="services-section">
          <Row>
            <Col xs={12} className={`${auditingServicesInView == '' ? 'sticky-section-heading' : 'sticky-section-heading-2'} `}>
              <div className="content-box text-center">
                <h2 className="content-title">
                  Benefits of <b>Our Audit Service</b>
                </h2>
                <p>
                  Our Software development audit service delivers a range of
                  benefits, ensuring your software is optimized for performance,
                  security, and compliance. By identifying and addressing issues
                  early, we help you enhance the overall quality and reliability
                  of your code, providing peace of mind and long-term value.
                </p>
              </div>
            </Col>
            <Col xs={isMobile ? '12' : "10"} md={isMobile ? '12' : "10"} className="mt-5 sectioncenter">
              {accordionData.map((module, index) => (
                <div key={index} className={`module mod-${index + 1}`}>
                  <Row>
                    <Col xs={12} md={3} className="module-number">
                      {/* <img src={module.image} alt={module.title} height={100} width={150} /> */}
                      <p>{module.indexnumber}</p>
                    </Col>

                    <Col xs={12} md={9} className="module-details-grid">
                      <div className="software-exclusive-title">
                        <h3>{module.title}</h3>
                      </div>
                      <div className="software-exclusive-content">
                        <p>{module.text}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              ))}
            </Col>
          </Row>
        </Container>
      </Container>
      {/* Exclusive Section End */}

      {/* Other Services Section Start */}
      <Container className="services-section" id="services-section">
        <Row>
          <Col xs={12}>
            <div className="content-box">
              <div className="text-center mb-5">
                <h2
                  className="content-title mb-0"
                  id={isMobile ? "auditing-services" : "desktop Screen"}
                >
                  Other <b>Software Auditing Services</b>
                </h2>
                <img
                  src={border_center}
                  alt=""
                  className="title_border d-block mt-2 mb-2"
                />
              </div>
            </div>
          </Col>
          <Col xs={12} md={4} className="banner-card2 mb-5">
            <Row>
              <Col xs={12} md={12}>
                <div className="service-box s1">
                  <h5>Code Refactoring:</h5>
                  <ul className="service-list">
                    <li>Optimize existing code</li>
                    <li>Improve performance</li>
                    <li>Enhance readability</li>
                  </ul>
                </div>
              </Col>
              <Col xs={12} md={12}>
                <div className="service-box s2">
                  <h5>Usability Evaluation</h5>
                  <ul className="service-list">
                    <li>Assess user experience</li>
                    <li>Identify usability issues</li>
                    <li>Recommend enhancements</li>
                  </ul>
                </div>
              </Col>
              <Col xs={12} md={12}>
                <div className="service-box s5">
                  <h5>Technical Debt Assessment</h5>
                  <ul className="service-list">
                    <li>Identify code issues</li>
                    <li>Prioritize improvements</li>
                    <li>Plan remediation strategies</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>

          <Col xs={12} md={4} className="banner-card2 mb-5">
            <Row>
              <Col xs={12} md={12}>
                <div className="service-box s3">
                  <h5>Functional Testing</h5>
                  <ul className="service-list">
                    <li>Validate core functionalities</li>
                    <li>Ensure feature performance</li>
                    <li>Detect functional defects</li>
                  </ul>
                </div>
              </Col>
              <Col xs={12} md={12}>
                <div className="service-box s4">
                  <h5>Load Testing</h5>
                  <ul className="service-list">
                    <li>Simulate high traffic</li>
                    <li>Measure software scalability</li>
                    <li>Identify capacity issues</li>
                  </ul>
                </div>
              </Col>
              <Col xs={12} md={12}>
                <div className="service-box s5">
                  <h5>Integration Testing</h5>
                  <ul className="service-list">
                    <li>Test system interactions</li>
                    <li>Verify data flow</li>
                    <li>Ensure seamless connectivity</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>

          <Col xs={12} md={4} className="banner-card2 mb-5">
            <Row>
              <Col xs={12} md={12}>
                <div className="service-box s6">
                  <h5>Code Review Training</h5>
                  <ul className="service-list">
                    <li>Educate developers</li>
                    <li>Improve review skills</li>
                    <li>Enhance coding standards</li>
                  </ul>
                </div>
              </Col>
              <Col
                xs={12}
                md={12}
                id={isMobile ? "Mobile Screen" : "auditing-services"}
              >
                <div className="service-box s7">
                  <h5>Software Performance Tuning</h5>
                  <ul className="service-list" >
                    <li>Optimize system resources</li>
                    <li>mprove speed</li>
                    <li>Enhance efficiency</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* Other Services Section End */}

      {/* Approach Section Start */}
      <Container fluid className="approach-section" id={isMobile ? "MobileScreen" : "auditingservicesbox"} ref={sectionRef}>
        <Container className="approach">
          <Row>
            <Col xs={12} md={12}>
              <div className="content-box text-center">
                <div className="mb-5">
                  <h2 className="content-title mb-0">
                    Optimize <b>Your Software Today</b>
                  </h2>
                  <p>
                    Unlock the full potential of your software with our expert
                    audit services—ensuring security, performance, and
                    compliance for lasting success.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={isMobile ? "6" : "12"} md={3}>
              <div className="counter text-center">
                <strong>
                  {isVisible && (
                    <CountUp start={1} end={10} duration={5} separator="," />
                  )}
                  +
                </strong>
                <p>Year Experience</p>
              </div>
            </Col>
            <Col xs={isMobile ? "6" : "12"} md={3}>
              <div className="counter text-center">
                <strong>
                  {isVisible && (
                    <CountUp start={10} end={500} duration={4} separator="," />
                  )}
                  +
                </strong>
                <p>Total Projects</p>
              </div>
            </Col>
            <Col xs={isMobile ? "6" : "12"} md={3}>
              <div className="counter text-center">
                <strong>
                  {isVisible && (
                    <CountUp start={10} end={320} duration={3} separator="," />
                  )}
                  +
                </strong>
                <p>Satisfied Customers</p>
              </div>
            </Col>
            <Col xs={isMobile ? "6" : "12"} md={3}>
              <div className="counter text-center">
                <strong>
                  {isVisible && (
                    <CountUp start={10} end={90} duration={4} separator="," />
                  )}
                  %+
                </strong>
                <p>Client Retention</p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* Approach Section End */}

      {/* FAQ Section Start */}
      <Container className="services-section">
        <Row>
          <Col xs={12}>
            <div className="content-box">
              <div className="text-center mb-5">
                <h2 className="content-title mb-0">
                  Have Any Questions? <br /> <b>Clear Your Doubts</b>
                </h2>
                <img
                  src={border_center}
                  alt=""
                  className="title_border d-block mt-2 mb-2"
                />
              </div>
            </div>
            <Col
              xs={12}
              md={12}
              className="d-flex text-center justify-content-center"
            >
              <div className="col-12 col-md-9">
                <p className="text-black mb-5">
                  We are the industry&apos;s tested and proven leaders who offer
                  complete protection across critical areas of risk in your
                  organization&apos;s digital infrastructure.
                </p>
              </div>
            </Col>
          </Col>
          <Col xs={12} md={12}>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  What is the importance of a code audit?
                </Accordion.Header>
                <Accordion.Body>
                  A code audit is crucial for identifying security
                  vulnerabilities, performance bottlenecks, and compliance
                  issues. It helps ensure that your software runs efficiently,
                  securely, and meets industry standards.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  How does your code audit process work?
                </Accordion.Header>
                <Accordion.Body>
                  Our audit process includes an initial consultation, codebase
                  review, security assessment, performance analysis, compliance
                  check, documentation review, and a final report with
                  actionable recommendations.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  What measures do you take to ensure the confidentiality of our
                  code?
                </Accordion.Header>
                <Accordion.Body>
                  We prioritize confidentiality and security by signing a
                  Non-Disclosure Agreement (NDA) with all clients. This ensures
                  that your code and proprietary information are protected
                  throughout the audit process.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  What are the best practices for preparing for a code audit?
                </Accordion.Header>
                <Accordion.Body>
                  To prepare for a code audit, ensure your codebase is organized
                  and up-to-date, provide access to relevant documentation, and
                  clearly communicate any specific concerns or areas you want us
                  to focus on.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  Why should we choose your code audit services over others?
                </Accordion.Header>
                <Accordion.Body>
                  Our Software Code Audit services stand out due to our
                  comprehensive seven-step audit process, experienced team of
                  experts, and commitment to delivering actionable insights. We
                  focus on enhancing security, performance, and compliance,
                  tailored to your specific needs.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>Data Protection Evaluation</Accordion.Header>
                <Accordion.Body>
                  Examine data protection measures, including encryption, data
                  classification, and secure data handling practices, also
                  assess how sensitive information is stored, transmitted, and
                  processed.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  How long does a code audit take?
                </Accordion.Header>
                <Accordion.Body>
                  Examine data protection measures, including encryption, data
                  classification, and secure data handling practices, also
                  assess how sensitive information is stored, transmitted, and
                  processed.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  What will we receive after the audit is completed?
                </Accordion.Header>
                <Accordion.Body>
                  After completing the audit, you will receive a detailed report
                  outlining our findings, including identified issues and
                  actionable recommendations. We also offer ongoing support to
                  help implement the suggested improvements.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  How can I get started with your code audit services?
                </Accordion.Header>
                <Accordion.Body>
                  Getting started is easy—simply schedule a consultation with
                  us, and we will discuss your needs, review your software, and
                  outline the next steps for conducting a thorough code audit.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
      {/* FAQ Section End */}

      {/* Footer form Start */}
      <Container className="software-code-bottom-form">
        <Row>
          <Col xs={12} md={12}>
            <div className="popup-header">
              <p className="sub-title">Have a query?</p>
              <h2>
                Request for <b>Evaluation</b>
              </h2>
              <p className="help-text">
                Fill out this form and pick a time slot for discover session
                with team.
              </p>
            </div>
          </Col>
          <Col xs={12} md={12}>
            <form action="#" className="form full-form">
              <Row>
                <Col xs={12} md={4}>
                  <div className="form-group">
                    <label htmlFor="name">
                      Your Name <sup style={{ color: "red" }}>*</sup>
                    </label>
                    <input
                      type="text"
                      name="fullName"
                      className="form-control"
                      required
                      placeholder="Full name"
                      value={formData.fullName}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
                <Col xs={12} md={4}>
                  <div className="form-group">
                    <label htmlFor="name">
                      Contact Number <sup style={{ color: "red" }}>*</sup>{" "}
                    </label>
                    <input
                      type="number"
                      name="contactNumber"
                      className="form-control"
                      required
                      placeholder="Ex.: 9887469725"
                      value={formData.contactNumber}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
                <Col xs={12} md={4}>
                  <div className="form-group">
                    <label htmlFor="name">
                      Email <sup style={{ color: "red" }}>*</sup>
                    </label>
                    <input
                      type="email"
                      name="email"
                      required
                      className="form-control"
                      placeholder="test@example.com"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
                <Col xs={12} md={4}>
                  <div className="form-group">
                    <label htmlFor="name">Git Repo Link</label>
                    <input
                      type="url"
                      name="gitRepoLink"
                      className="form-control"
                      placeholder="Link"
                      value={formData.gitRepoLink}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
                <Col xs={12} md={4}>
                  <div className="form-group">
                    <label htmlFor="name">Date</label>
                    <input
                      type="Date"
                      name="date"
                      className="form-control"
                      placeholder="Link"
                      min={new Date().toISOString().split("T")[0]}
                      value={formData.date}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
                <Col xs={12} md={4}>
                  <div className="form-group">
                    <label htmlFor="name">Time</label>
                    <input
                      type="Time"
                      name="time"
                      className="form-control"
                      placeholder="Link"
                      value={formData.time}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
                <Col xs={12} md={12}>
                  <div className="form-group">
                    <label htmlFor="name">Website URL</label>
                    <input
                      type="url"
                      className="form-control"
                      name="websiteUrl"
                      placeholder="Link"
                      value={formData.websiteUrl}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="form-group">
                    <Form.Check
                      inline
                      label="Yes, send me a mutual NDA (Non-Disclosure Agreement)"
                      id="checkbox_id"
                      htmlFor="checkbox_id"
                      name="group1"
                      type={"checkbox"}
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />

                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={googleReCaptchaKey}
                      onChange={onChange}
                    />
                  </div>
                  <p className="mt-1">
                    {" "}
                    <strong> All project secured by NDA & IP is your’s </strong>
                  </p>
                  <p className="mt-2">
                    <strong> 100% Secure. Zero Spam. </strong>
                  </p>
                </Col>
                <Col xs={12} md={6} className="btnForm">
                  <div className="form-group  mb-0">
                    {isLoading ? (
                      <span className="btn loader float-end d-flex "></span>
                    ) : (
                      <input
                        type="Submit"
                        disabled={!isSubmit}
                        onClick={onSubmit}
                        className="btn btn-submit"
                        value="Submit"
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
      {/* Footer form End */}

      <Footer />
      {isPopupTalkExportOpen && (
        <TalkExpert onClose={handleClosePopupTalkExport} />
      )}
    </div>
  );
};

export default Home;
