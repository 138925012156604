import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CountUp from "react-countup";

const ApproachSection = () => {
  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);


  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 } // Adjust the threshold based on your needs
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, [sectionRef]);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)"); // Define mobile screen width (768px is commonly used as a breakpoint)

    // Update state based on the initial screen size
    setIsMobile(mediaQuery.matches);

    // Define a function to handle screen width changes
    const handleResize = (e) => {
      setIsMobile(e.matches);
    };

    // Add a listener for screen width changes
    mediaQuery.addEventListener("change", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      mediaQuery.removeEventListener("change", handleResize);
    };
  }, []);
  
  return (
    <>
      <Container fluid className="approach-section" ref={sectionRef}>
        <Container className="approach">
          <Row>
            <Col xs={12} md={12}>
              <div className="content-box text-center">
                <div className="mb-5">
                  <h2 className="content-title mb-0">
                    A unified platform approach for preventing{" "}
                    <b>Cyber attacks</b>{" "}
                  </h2>
                  <p>
                    We are the industry&apos;s tested and proven leaders who offer
                    complete protection across critical areas of risk in your
                    organization&apos;s digital infrastructure.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={isMobile ? "6" : "12"} md={3}>
              <div className="counter text-center">
                <strong>
                  {isVisible && (
                    <CountUp start={1} end={10} duration={5} separator="," />
                  )}
                  +
                </strong>
                <p>Year Experience</p>
              </div>
            </Col>
            <Col xs={isMobile ? "6" : "12"} md={3}>
              <div className="counter text-center">
                <strong>
                  {isVisible && (
                    <CountUp start={10} end={500} duration={4} separator="," />
                  )}
                  +
                </strong>
                <p>Total Projects</p>
              </div>
            </Col>
            <Col xs={isMobile ? "6" : "12"} md={3}>
              <div className="counter text-center">
                <strong>
                  {isVisible && (
                    <CountUp start={10} end={320} duration={3} separator="," />
                  )}
                  +
                </strong>
                <p>Satisfied Customers</p>
              </div>
            </Col>
            <Col xs={isMobile ? "6" : "12"} md={3}>
              <div className="counter text-center">
                <strong>
                  {isVisible && (
                    <CountUp start={10} end={56} duration={4} separator="," />
                  )}
                  %+
                </strong>
                <p>Client Retention</p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default ApproachSection;
