import React from "react";
import MenuBar from "./comman/MenuBar";
import Footer from "./comman/Footer";

import { Container, Row, Col } from "react-bootstrap";
import { dots } from "../assets";
import bannerImage from "../assets/inner-bg.webp";

const Terms = () => {
  return (
    <div className="tnc">
      <MenuBar />
      {/* Banner Section */}
      <Container fluid>
        <Row>
          <div className="bannerArea animation">
            <img
              src={bannerImage}
              alt="Banner"
              className="w-100 banner-image svg"
            />
            <img src={dots} alt="Banner" className="dots left svg" />
            <img src={dots} alt="Banner" className="dots right svg" />
            <Container>
              <Row>
                <Col xs={12} md={12}>
                  <div className="banner-caption inner-caption">
                    <h1 className="banner-title">Terms and Conditions</h1>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Row>
      </Container>
      {/* Banner Section */}

      {/* Banner Section */}
      <Container>
        <Row>
          <Col md={12}>
            <div className="content-wrapper">
              <p>
                The following are terms of a legal agreement between you and
                Scrutinys. Here, we clearly define aspects of our business
                relationship with you.
              </p>

              <p>
                <strong>
                  1. General Work Terms and Client Responsibilities &
                  Liabilities
                </strong>
              </p>
              <p>
                All site content (text and multimedia) will be the sole
                responsibility of the client to provide to Scrutinys. Such
                should be provided prior to commencing the work.
              </p>
              <p>
                Client is solely responsible to take proper back-up of all
                content on their site prior to letting Scrutinys undertake the
                required course of action towards meeting the contract. Any loss
                or damage to existing data shall not be a responsibility of
                Scrutinys under any circumstances.
              </p>
              <p>
                The Contract does not hold Scrutinys responsible for any data
                entry, web hosting or custom artwork/graphics related work/tasks
                unless otherwise specifically mentioned, paid for and agreed to
                by both the parties towards such. Any artwork, images, or text
                supplied and/or designed by Scrutinys on behalf of the client,
                will remain the property of Scrutinys and/or it&apos;s suppliers
                unless otherwise agreed.
              </p>
              <p>
                While Scrutinys will do its best to achieve all deliveries
                within the estimated time, there may, at times, be the need to
                extend or adjust time in cases of any unavoidable and
                non-forecasted situations like those of deployment issues,
                dependencies, 3rd-Party support, development bottle-necks,
                resource unavailability due to emergency, communication delays
                and the like.
              </p>
              <p>
                The Client retains the copyright to data, files and graphic
                logos provided by the Client and grants Scrutinys the rights to
                publish and use such material. The Client must obtain permission
                and rights to use any information or files that are copyrighted
                by a third party. The Client is further responsible for granting
                Scrutinys permission and rights for use of the same and agrees
                to indemnify and hold harmless Scrutinys from any and all claims
                resulting from the Client&apos;s negligence or inability to obtain
                proper copyright permissions. A contract for Web site design
                and/or placement shall be regarded as a guarantee by the Client
                to Scrutinys that all such permissions and authorities have been
                obtained. Evidence of permissions and authorities may be
                requested.
              </p>
              <p>
                Scrutinys will not accept responsibility for any alterations
                caused by the Client or a third party occurring to the Client&apos;s
                pages/website once installed/deployed. Such alterations include,
                but are not limited to additions, modifications or deletions.
                Scrutinys may require a one-off Web Development charge before
                resolving any issues that may arise.
              </p>

              <p>
                <strong>2. Payments</strong>
              </p>
              <p>
                Scrutinys accepts payments by cheque, Cash or Bank Transfers
                (although we reserve the right to decline payment in any of
                these forms without notice). Without limitation, Scrutinys
                reserves the right to withdraw any payment methods at any time
                and to vary its prices without prior notice.
              </p>
              <p>
                A cancellation fee may be charged if the Customer cancels the
                Service prior to completion. The fee will be equal to the amount
                of work completed at the point of cancellation.
              </p>
              <p>
                A non-payment of cancellation fee and/or over-due amount will
                result in legal action upon necessity.
              </p>

              <p>
                <strong>3. Support and 3rd-Party</strong>
              </p>
              <p>
                As the site launches, we offer free support for the first month.
                After one month of free service, we charge according to our
                various price packages best-suited to client&apos;s requirement. We
                also provide attractive discounts if the client chooses a higher
                price package. The scope of support only includes any bug fixing
                and email support and excludes any issues related to the site
                architecture, rule changes and add-ons/enhancements.
              </p>
              <p>
                Any 3rd-Party support, product and/or service being used/
                integrated into the site which requires licensing, payment,
                copyright, etc. shall be the sole responsibility and liability
                of and be provided by the client or will be procured by
                Scrutinys on behalf of the client on pre-payment for the cost of
                such procurement. The fee charged by Scrutinys is exclusive of
                out-of-pocket expenses and expense claims filed by third party
                products/services involved.
              </p>
              <p>
                No guarantees or warranties shall be provided by Scrutinys for
                the accuracy or performance of such 3rd-Party product/service.
              </p>

              <p>
                <strong>4. Re-work, Enhancements/Add-ons and Billing</strong>
              </p>
              <p>
                Any additional features not envisaged in the scope of work would
                be entertained through a Change Management process and be
                additional billed. Scope Creeps after wireframe sign off would
                be billed as additional and time lines and cost for delivery
                would increase.
              </p>
              <p>
                Most tweaks such as minor changes/re-work are normally just
                completed, however, if we feel this is being abused, we will
                build a list of works found being the grounds of abuse and bill
                such additionally per the total time efforts involved and
                undertaken to achieve the work/tasks at business rates ranging
                from US $ 15 to 20 per hour.
              </p>
              <p>
                Whilst we try our best to cover most changes within the budget
                of the site, some changes are classified as enhancements/add-ons
                to the system and become chargeable; we will advise you before
                we start work of any such items.
              </p>

              <p>
                <strong>5. Limitations of Liability</strong>
              </p>
              <p>
                Scrutinys will use reasonable skill and care in providing the
                Service. However, we make no representation and exclude any
                warranty, express or implied, as to the availability, quality,
                accuracy, timeliness, completeness, performance or fitness of
                the Service.
              </p>
              <p>
                Scrutinys hereby excludes itself, its Employees and or Agents
                from: all and any liability for loss or damage caused by any
                inaccuracy; omission; delay or error, whether the result of
                negligence or other cause in the production of the website; All
                and any liability for loss or damage to clients&apos; artwork/photos,
                data/content supplied for the site. This is whether the loss or
                damage results from negligence or otherwise.
              </p>
              <p>
                Scrutinys cannot make guarantees of service on behalf of third
                party organizations and will not be held liable for the failure
                in any service provided by third parties.
              </p>

              <p>
                <strong>6. Approvals and Delivery</strong>
              </p>
              <p>
                The project will involve various stages and the work for the
                next stage will only start after receiving the sign off on and
                relevant payments for the previous stage as agreed.
              </p>
              <p>
                The project will involve various stages and the work for the
                next stage will only start after receiving the sign off on and
                relevant payments for the previous stage as agreed.
              </p>
              <p>
                On completion of the Service (Website design and/or website
                development), the website will be uploaded to the Customer area
                of Scrutinys server for approval. Upon approval by the Client,
                the website will be uploaded to the destination server where the
                site shall stay. Scrutinys reserves the right to delay uploading
                of the website until full payment has been received.
              </p>
              <p>
                Scrutinys holds the Right to publish and use the completed work
                and/or even the deployed final produce/website for references to
                other potential clients. In circumstances such is required to be
                withheld, client shall notify Scrutinys well in advance and
                request prior and proper approvals towards the same.
              </p>

              <p>
                <strong>7. Timescale</strong>
              </p>
              <p>
                Scrutinys will aim to complete all services within the agreed
                timescale. The timescale will commence on receipt of both the
                agreed % deposit (ranging from 30% to 50% of the project price
                as mutually discussed and agreed prior to contract finalization)
                as acceptance and all website content from the Customer.
              </p>

              <p>
                <strong>8. Severability</strong>
              </p>
              <p>
                In the event any one or more of the provisions of this Agreement
                and/or Order shall be held to be invalid, illegal or
                unenforceable, the remaining provisions of this Agreement and/or
                Order shall be unimpaired and the Agreement and/or Order shall
                not be void for this reason alone. Such invalid, illegal or
                unenforceable provision shall be replaced by a mutually
                acceptable valid, legal and enforceable provision, which comes
                closest to the intention of the parties underlying the invalid,
                illegal or unenforceable provision.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      {/* Banner Section */}
      <Footer />
    </div>
  );
};

export default Terms;
