import React, { useRef, useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import ReCAPTCHA from 'react-google-recaptcha';
import { health, job, pizza, culture, dots } from "../../assets";
import SendEmail from "../../apies/sendEmail";
import { toast } from "react-toastify";
import { googleReCaptchaKey } from "../../constants";
const initialFormData = {
  fullName: "",
  email: "",
  contactNumber: "",
  file: "",
};
const Career = (props) => {
  const [formData, setFormData] = useState(initialFormData);
  const fileUploadRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [, setIsCaptchaSuccessful] = React.useState(false);
  const recaptchaRef = useRef(null);

  function onChange(value) {
    setIsCaptchaSuccessful(true);
    console.log("captcha value:", value);
  }
  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const captchaValue = recaptchaRef.current.getValue();
    if (!captchaValue) {
      toast.error('Please verify that you are not a robot.');
      return;
    }
    const data = new FormData();
    const html = `<p>A new submission has been received:</p>
        <p><strong>Full Name:</strong> ${formData.fullName}</p>
        <p><strong>Email:</strong> ${formData.email}</p>
        <p><strong>Contact Number:</strong> ${formData.contactNumber}</p>
        <p><strong>File:</strong> ${formData.file}</p>`;
    const subject = "New Submission from career";
    const heading = "New Submission";
    data.append("email", formData.email);
    data.append("subject", subject);
    data.append('heading', heading);
    data.append('htmlContent', html);

    data.append("file", fileUploadRef.current.files[0]);
    setIsLoading(true); // show loader
    SendEmail(data, false).then((response) => {
      // console.log(response.data);
      setIsLoading(false); // hide loader      
      if (response.data.status) {
        // alert(response.data.message);
        toast.success(response.data.message);
        setFormData(initialFormData);
      }else {
        setFormData(initialFormData);

        const errorText =
          response.data?.data?.details.length > 0
            ? response.data.data.details[0].message
            : response.data.message;
        toast.error(errorText);
      }
      recaptchaRef.current.reset();
    });
  };
  const isSubmit =
    formData.fullName && formData.email && formData.contactNumber;
  return (
    <>
      <Modal
        show={true}
        // eslint-disable-next-line react/prop-types
        onHide={props.onClose}
        dialogClassName="modal-90w"
        aria-labelledby="modal-title"
        className="careerPopup"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img src={dots} alt="Banner" className="dots top-right" />
          <img src={dots} alt="Banner" className="dots left-bottom" />
          <Row>
            <Col xs={12} md={6}>
              <div className="popup-header">
                <p className="sub-title">Looking for career growth?</p>
                <h2>
                  Boost your{" "}
                  <b>
                    Career <br />
                    Path
                  </b>{" "}
                  with us
                </h2>
                <p className="help-text">
                  There are lots of benefits to working here,these are just the
                  perks
                </p>
              </div>
              <div className="popup-content activities">
                <div className="activity-box">
                  <img src={health} alt="" />
                  <p>Health, Dental and Vision</p>
                </div>
                <div className="activity-box">
                  <img src={job} alt="" />
                  <p>Best Job Opportunities</p>
                </div>
                <div className="activity-box">
                  <img src={pizza} alt="" />
                  <p>Weekly Catered Lunches</p>
                </div>
                <div className="activity-box">
                  <img src={culture} alt="" />
                  <p>Best Work Culture</p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <form action="#" className="form">
                <h3 className="hand-icon">👋</h3>
                <div className="form-group">
                  <label htmlFor="name">
                    Your Name <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleInputChange}
                    placeholder="Full name"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="name">
                    Contact Number <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    name="contactNumber"
                    value={formData.contactNumber}
                    onChange={handleInputChange}
                    placeholder="Ex.: 9887469725"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="name">
                    Your Email <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder="Ex: email@example.com"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="name">Upload Resume (Max 3MB)</label>
                  <input
                    type="file"
                    className="form-control"
                    placeholder="Upload"
                    ref={fileUploadRef}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      const fileSize = file?.size / 1024 / 1024; // size in MB
                      const fileType = file?.type;

                      if (fileSize > 3) {
                        toast.error("File size exceeds 3MB");
                      } else if (!fileType.includes("application")) {
                        toast.error(
                          "Invalid file type. Only document files are allowed."
                        );
                      } else {
                        setFormData((prev) => ({ ...prev, file: file.name }));
                      }
                    }}
                  />
                  <div className="custom-upload">
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      name="file"
                      value={formData.file}
                      placeholder="Ex: fileName"
                    />
                    <button
                      className="btn btn-upload"
                      onClick={(e) => {
                        // Add an onClick event to the button
                        e.preventDefault(); // Prevent the default form submission
                        fileUploadRef.current.click(); // Trigger the file input click event
                      }}
                    >
                      Upload
                    </button>
                  </div>
                </div>
                <div className="form-group">
                <ReCAPTCHA
                          ref={recaptchaRef}
                          sitekey={googleReCaptchaKey}
                          onChange={onChange}
                      />
                </div>
                <div className="form-group mb-0">
                  {isLoading ? (
                    <span className="btn loader float-end d-flex "></span>
                  ) : (
                    <input
                      type="Submit"
                      className="btn btn-submit"
                      value="Submit"
                      disabled={!isSubmit} // Disable button if form is not valid or if it's loading
                      onClick={onSubmit}
                    />
                  )}
                </div>
              </form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Career;
