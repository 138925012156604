import React, { useState , useEffect, useRef  } from "react";

import { Link } from "react-router-dom";
import MenuBar from "./comman/MenuBar";
import Footer from "./comman/Footer";
import TalkExpert from "./comman/TalkExpert";
import RotatingText from "./comman/RotatingText";
import { Container, Row, Col } from "react-bootstrap";
import "../styles/loader.css";
import bannerImage from "../assets/banner1.webp";


import {
  partner,
  border_center,
  t1,
  t2,
  t3,
  t4,
  t5,
  t6,
  t7,
  dots,
  founder1,
  border_left,
  rocket,
  business,
  fifty,
  three_fourth,
} from "../assets";

const accordionData = [
  {
      title: "Maintainability",
      text: "This is a crucial aspect which focuses on the ease with which a software application can be maintained, updated, and extended over its lifecycle. We review the codebase to identify code smells, duplication & issues. Assessing coding standards, comments and up-to-date documentations. Evaluate the use of version control, automated testing and continuous integration CI/CD practices. We ensure that the software remains viable and efficient in the long term.",
      image: t1
  },
  {
      title: "Security",
      text: "Ensure that security practices are in place to protect against vulnerabilities and threats. Regular security audits and updates are essential for maintaining the software&apos;s security posture.",
      image: t2
  },
  {
      title: "Extensibility",
      text: "Assessing extensibility in software development review involves evaluating how well a software application can accommodate future changes, enhancements, and new features without major disruptions or extensive code modifications.",
      image: t3
  },
  {
      title: "Scalability",
      text: "Assess the software&apos;s ability to handle increased loads and data volumes. Scalable and efficient code reduces the need for frequent performance optimizations.",
      image: t4
  },
  {
      title: "Process Automation",
      text: "We can significantly improve efficiency and consistency throughout the software development lifecycle. While automation often involves tools and scripts, it can also extend to reviewing and optimizing processes to reduce cost of maintenance over time.",
      image: t5
  },
  {
      title: "Cost",
      text: "It is essential to check the use of platform infrastructure & tools are optimized as per cost and in future buisness expands for managing the budget effectively and ensuring that the project stays within financial constraints.",
      image: t6
  },
  {
      title: "Compliance",
      text: "We ensures that the software and its usage align with relevant laws, regulatory compliance, licenses, and organizational policies.",
      image: t7
  }
];


function isInView(element, inViewType) {
  const viewport = {};
  viewport.top = window.scrollY;
  viewport.bottom = viewport.top + window.innerHeight;
  const bounds = {};
  bounds.top = element.offsetTop;
  bounds.bottom = bounds.top + element.offsetHeight;

  switch (inViewType) {
    case 'bottomOnly':
      return ((bounds.bottom <= viewport.bottom) && (bounds.bottom >= viewport.top));
    case 'topOnly':
      return ((bounds.top <= viewport.bottom) && (bounds.top >= viewport.top));
    case 'both':
      return ((bounds.top >= viewport.top) && (bounds.bottom <= viewport.bottom));
    default:
      return ((bounds.top >= viewport.top) && (bounds.bottom <= viewport.bottom));
  }
}

import CountUp from 'react-countup';
const Home = () => {

  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const [isPopupTalkExportOpen, setPopupTalkExportOpen] = useState(false);

  const handleOpenPopupTalkExport = () => {
    setPopupTalkExportOpen(true);
  };
  const handleClosePopupTalkExport = () => {
    setPopupTalkExportOpen(false);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // Check if the top of the section is at the top of the viewport     
          //console.log(entry.boundingClientRect.bottom +'===='+ window.innerHeight);
                   
          if (entry.boundingClientRect.top <= 0 && entry.isIntersecting) {
            setIsVisible(true);
          } // Check if the bottom of the section is out of view
          else if (entry.boundingClientRect.bottom <= 0) {
            setIsVisible(false); // Remove 'in-view' class when section bottom leaves the viewport
          } 
          else {
            setIsVisible(false); // Remove 'in-view' class if not visible
          }
        });
      },
      {
        root: null, // relative to the viewport
        rootMargin: '0px 0px -100% 0px', // Trigger when the top of the section reaches the top of the viewport
        threshold: 0, // Set threshold to 0 to trigger as soon as the top of the element hits the top of the viewport
      }
    );
  
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }
  
    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [setIsVisible]);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)'); // Define mobile screen width (768px is commonly used as a breakpoint)
    
    // Update state based on the initial screen size
    setIsMobile(mediaQuery.matches);

    // Define a function to handle screen width changes
    const handleResize = (e) => {
        setIsMobile(e.matches);
    };

    // Add a listener for screen width changes
    mediaQuery.addEventListener('change', handleResize);

    // Clean up the event listener on component unmount
    return () => {
        mediaQuery.removeEventListener('change', handleResize);
    };
    }, []);

    const [auditingServicesInView, setAuditingServicesInView] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const auditingServicesElement = document.getElementById('founder-column');
            if (auditingServicesElement) {
                setAuditingServicesInView(isInView(auditingServicesElement, 'topOnly'));
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);


  return (
    <div className="home">
      <MenuBar />

      {/* Banner Section */}
      <Container fluid>
        <Row>
          <div className="bannerArea animation">
            <img
              src={bannerImage}
              alt="Banner"
              className="w-100 banner-image svg"
            />
            <img src={dots} alt="Banner" className="dots left svg" />
            <img src={dots} alt="Banner" className="dots right svg" />
            <Container>
              <Row>
                <Col xs={12} md={7}>
                  <div className="banner-caption">
                    <h1 className="banner-title animated-text fadeInDown">
                      Evaluate Your <br className="hide-br"/>{" "}
                      <RotatingText className="rotate-text" textArray={['Requirements', 'Designs', 'Development', 'Testing Assurance', 'Security', 'User Experience']}/>
                      <br/> with Scrutinys
                    </h1>
                    <p className="text-white mt-3 mb-5 paraFor">
                      More than 12 years of experience in Code Auditing and
                      Platform Evaluation
                    </p>
                  </div>

                  <div className="counters">
                    <div className="count-box text-white">
                      <h1><CountUp start={400} end={500} duration={5} separator="," />+</h1>
                      <p>Audits</p>
                    </div>
                    <div className="count-box text-white">
                      <h1><CountUp start={250} end={320} duration={5} separator="," />+</h1>
                      <p>Customers</p>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={5} className="relative">
                <div className="pulse_button">
                  <div className="circle">
                      <div className="banner-action">
                        <a onClick={handleOpenPopupTalkExport} >
                          Get Free Consultation
                          </a>
                      </div>
                  </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Row>
      </Container>
      {/* Banner Section */}

      {/* Partner Section */}
      <Container className="partner-section">
        <Row>
          <Col xs={12} md={6}>
            <div className="content-box">
              <h2 className="content-title">
                Your Partner <br /> <b>for the future</b>
              </h2>
              <p>
                At Scrutinys, we are a team of experienced professionals who are
                passionate code enthusiasts committed to enhance / improve code
                quality and ensuring the dependability and safety of your
                software ventures. With a rich history of extensive background
                in code evaluation, an unwavering commitment and a steadfast
                dedication to adhering to the industry standards in delivering
                holistic solutions that empower your development teams to craft
                robust and scalable software, we offer all-encompassing
                solutions that empower your development teams to create
                resilient and expandable software.
              </p>
              <strong>
                Let us be your code&apos;s best friend on the journey to reliability
                and innovation.
              </strong>
              <Link to="/what-we-do" className="btn btn-primary mt-4">
                Explorer our Services
              </Link>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="partner_thumb">
              <img src={partner} alt="" />
            </div>
          </Col>
        </Row>
      </Container>
      {/* Partner Section End*/}

      {/* Exclusive Section Start */}
      <Container className="exclusive-section" id={isVisible ? 'in-view' : ''} ref={sectionRef}>
        <Row>
          <Col xs={12} className={`${auditingServicesInView == '' ? 'sticky-section-heading' : 'sticky-section-heading-2'} `}>
            <div className="content-box">
              <div className="text-center mb-5">
                <h2 className="content-title mb-0">
                  What makes us <b>exclusive?</b>
                </h2>
                <img
                  src={border_center}
                  alt=""
                  className="title_border d-block mt-2 mb-2"
                />
                <p>One platform, complete protection</p>
              </div>
            </div>
          </Col>
          
          <Col xs={isMobile ? '12' : "10"} md={isMobile ? '12' : "10"} className="mt-5 sectioncenter">
              {accordionData.map((module, index) => (
                  <div key={index} className={ isMobile ? `module mod-home-${index + 1}`:`module mod-home-${index + 1}`}>
                      <Row>
                          <Col xs={12} md={3} className="module-details-grid accordion-img">
                              {<img src={module.image} alt={module.title} />}
                          </Col>

                          <Col xs={12} md={9} className="module-details-grid">
                              <div className="software-exclusive-title">
                                  <h3>{module.title}</h3>
                              </div>
                              <div className="software-exclusive-content">
                                  <p>{module.text}</p>
                              </div>
                          </Col>
                      </Row>

                  </div>
              ))}
          </Col>

        </Row>
      </Container>
      {/* Exclusive Section End */}

      {/* Hey Founder Section Start */}
      <Container fluid className="hey_founder" id={isMobile ? 'founder-column' : "desktop-screen"}>
        <Container className="founder_issue">
          <Row>
            <Col xs={12} md={6} className="desktopOnly">
              <div className="content-box">
                <div className="mb-5">
                  <h2 className="content-title mb-0">
                    Hey <b>Founder 👋</b>
                  </h2>
                  <p className="sub-title">
                    Are you experiencing <br /> difficulties with
                  </p>
                </div>
              </div>
              <img src={founder1} alt="" className="founder-thumb" />
            </Col>

            <Col xs={12} md={6}>
              <Row>
                <Col xs={12} md={6} className="mobileOnly">
                  <div className="content-box">
                    <div className="mb-5">
                      <h2 className="content-title mb-0">
                        Hey <b>Founder 👋</b>
                      </h2>
                      <p className="sub-title">
                        Are you experiencing <br /> difficulties with
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="box">
                    <p>
                      Adapting to the changing landscape of HIPAA compliance and
                      industry standards?
                    </p>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="box mt-4">
                    <p>
                      Navigating the complex terrain of PCI DSS compliance and
                      industry standards?
                    </p>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="box mt-4">
                    <p>
                      Bridging the intricate landscape of GDPR compliance and
                      industry standards ?
                    </p>
                  </div>
                </Col>
                <Col xs={12} md={6}  id={isMobile ? 'Mobile Screen' : "founder-column"}>
                  <div className="box mt-4">
                    <p>
                      Addressing the complexities of disaster recovery planning
                      and staying aligned with critical industry standards ?
                    </p>
                  </div>
                </Col>
                <Col>
                  <div className="cta">
                    <p>Stay confident. We are here to help you.</p>
                    <Link
                      className="btn btn-primary btn-large"
                      onClick={handleOpenPopupTalkExport}
                    >
                      Help me with compliances
                    </Link>
                  </div>
                </Col>
                <Col xs={12} md={6} className="mobileOnly">
                  <img src={founder1} alt="" className="founder-thumb" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* Hey Founder Section End */}

      {/* Startups Breakdown? Start */}
      <Container className="startup-section">
        <a className="float-btn" onClick={handleOpenPopupTalkExport}>
          Get Free Consultation
        </a>
        <Row>
          <Col xs={12} md={7}>
            <div className="content-box">
              <div className="mb-5">
                <h2 className="content-title mb-0 text-black">
                  What Leads to IT <br />{" "}
                  <b className="text-black">Startups Breakdown?</b>{" "}
                </h2>
                <img src={border_left} alt="" className="mt-2 mb-2" />
                <p className="text-black">
                  IT startups industry often fails due to Lack of Market
                  Research, Inadequate Product-Market Fit, Team and Technical
                  Challenges, Legal and Compliance Issues, Failure to Innovate
                  etc.
                </p>
              </div>
              <Row>
                <Col xs={12} md={4}>
                  <div className="fact-box">
                    <img src={business} alt="" className="mb-5" />
                    <p>
                      <b>1 in 5 </b>businesses fold in <b>year one</b>
                    </p>
                  </div>
                </Col>
                <Col xs={12} md={4}>
                  <div className="fact-box">
                    <img src={fifty} alt="" className="mb-5" />
                    <p>
                      <b>50% </b>fail by the four-year mark
                    </p>
                  </div>
                </Col>
                <Col xs={12} md={4}>
                  <div className="fact-box">
                    <img src={three_fourth} alt="" className="mb-5" />
                    <p>
                      <b>80% </b>last fewer than twenty years
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={12} md={5}>
            <div className="rocket_thumb">
              <img src={rocket} alt="" />
            </div>
          </Col>
        </Row>
      </Container>
      {/* Startups Breakdown? End */}

      <Footer />
      {isPopupTalkExportOpen && (
        <TalkExpert onClose={handleClosePopupTalkExport} />
      )}
    </div>
  );
};

export default Home;
