import React, { useState, useEffect } from "react";
import "../../styles/index.css";
import { closeIcon, logo } from "../../assets";
import { navLinks } from "../../constants";
import { Link, useLocation } from "react-router-dom";
import { Container, Navbar, Nav, Button } from "react-bootstrap";
import Career from "./Career";
import RequestEvaluation from "./RequestEvaluation";
import TalkExpert from "./TalkExpert";

const MenuBar = () => {
  const [, setActive] = useState("Home");
  const location = useLocation();
  const [isPopupCareerOpen, setPopupCareerOpen] = useState(false);
  const [isPopupEvaluationOpen, setPopupEvaluationOpen] = useState(false);
  const [isPopupTalkExportOpen, setPopupTalkExportOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const [scroll, setScroll] = useState(0);
  const [menuClass, setMenuClass] = useState(
    "menubar navbar navbar-expand-lg navbar-light"
  );

  const handleOpenPopupCareer = () => {
    setPopupCareerOpen(true);
  };
  const handleClosePopupCareer = () => {
    setPopupCareerOpen(false);
  };

  const handleOpenPopupEvaluation = () => {
    setPopupEvaluationOpen(true);
  };
  const handleClosePopupEvaluation = () => {
    setPopupEvaluationOpen(false);
  };
  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleOpenPopupTalkExport = () => {
    setPopupTalkExportOpen(true);
  };
  const handleClosePopupTalkExport = () => {
    setPopupTalkExportOpen(false);
  };
 

  useEffect(() => {
    const handleScroll = () => {
      const newScroll = window.scrollY;
      setScroll(newScroll);

      if (newScroll >= 200) {
        setMenuClass("menubar navbar navbar-expand-lg navbar-light darkHeader");
      } else {
        setMenuClass("menubar navbar navbar-expand-lg navbar-light");
      }
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scroll]);

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className={menuClass}
      id="top"
      expanded={expanded}
      onToggle={handleToggle}
    >
      <Container>
        <Link to="/" className="navbar-brand">
          <img src={logo} alt="Scrutinys" className="logo" />
        </Link>
        <Navbar.Toggle />
        <Navbar.Offcanvas className="justify-content-start align-items-center">
          <Link className="menu-close" onClick={handleToggle}>
            <img src={closeIcon} alt="" />
          </Link>
          <Nav className="ms-auto mobile-menu">
            <Link to="/" className="navbar-brand">
              <img src={logo} alt="Scrutinys" className="logo" />
            </Link>
            {navLinks.map((nav) => (
              <Link
                key={nav.id}
                to={`/${nav.id}`}
                className={`nav-link ${location.pathname === "/what-we-do"
                  ? "text-primary"
                  : "text-black"
                  }`}
                onClick={() => setActive(nav.title)}
              >
                {" "}
                {nav.title}
              </Link>
            ))}
            <Link
              className={`nav-link ${location.pathname === "/software-code-audit-services"
                ? "text-primary"
                : "text-black"
                }`}
              to="/software-code-audit-services"
              style={{ color: "white", marginRight: "15px" }}
            >
              Software Code Audit Services
            </Link>
            <Link
              className={`nav-link text-black`}
              onClick={handleOpenPopupCareer}
            >
              Career
            </Link>
            {isPopupCareerOpen && <Career onClose={handleClosePopupCareer} />}
            
            <a className="nav-link d-block d-md-none text-black" href="/blog">Blog</a>

            <Button
              variant="flat"
              className="btn btn-primary"
              onClick={handleOpenPopupEvaluation}
            >
              Request for Evaluation
            </Button>
            {isPopupEvaluationOpen && (
              <RequestEvaluation onClose={handleClosePopupEvaluation} />
            )}

            <div className="circle">
              <div className="banner-action">
                <a className="pulse_button" onClick={handleOpenPopupTalkExport}>
                  Get Free Consultation
                </a>
              </div>
            </div>
            {isPopupTalkExportOpen && (
              <TalkExpert onClose={handleClosePopupTalkExport} />
            )}
          </Nav>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default MenuBar;
