import React from 'react';

const ReplaceSvg = () => {
  React.useEffect(() => {
    const replaceSvg = () => {
      //Change the class name, if it has to be applied for more SVG elements
      document.querySelectorAll('img.svg').forEach(($img) => {
        const imgID = $img.getAttribute('id'); // ID attribute
        const imgClass = $img.getAttribute('class'); // Class Name
        const imgURL = $img.getAttribute('src'); // URL of the SVG image

        fetch(imgURL)
          .then((response) => response.text())
          .then((data) => {
            //The data param contains the XML data of the SVG image
            //alert(new XMLSerializer().serializeToString(data));

            // Get the SVG tag, ignore the rest
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(data, 'application/xml');
            const $svg = xmlDoc.querySelector('svg');
            if( $svg !== null ) {
              // Give the image's ID to the SVG
              if (imgID !== null) {
                $svg.setAttribute('id', imgID);
              }

              // Give the image's class to the SVG
              if (imgClass !== null) {
                $svg.setAttribute('class', imgClass + ' replaced-svg');
              }

              // Remove any invalid XML tags as per http://validator.w3.org
              $svg.removeAttribute('xmlns:a');

              // Check if the viewport is set, else we gonna set it if we can.
              if (!$svg.getAttribute('viewBox') && $svg.getAttribute('height') && $svg.getAttribute('width')) {
                $svg.setAttribute('viewBox', '0 0 ' + $svg.getAttribute('height') + ' ' + $svg.getAttribute('width'));
              }

              // Replace image with new SVG
              $img.replaceWith($svg);
            }
          })
          .catch((error) => {
            console.error('Error fetching SVG:', error);
          });
      });
    };

    replaceSvg();
  }, []); // Empty dependency array means this effect will run once on component mount

  return null; // You can return a placeholder element if needed
};

export default ReplaceSvg;
