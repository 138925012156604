import React, { useRef, useState } from "react";
import { Form, Modal, Row, Col } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { dots } from "../../assets";
import SendEmail from "../../apies/sendEmail";
import { toast } from "react-toastify";
import { googleReCaptchaKey } from "../../constants";

const RequestEvaluation = (props) => {
  const [, setIsCaptchaSuccessful] = React.useState(false);
  const recaptchaRef = useRef(null);

  function onChange(value) {
    setIsCaptchaSuccessful(true);
    console.log("captcha value:", value);
  }
  // here we handle form data
  const initialFormData = {
    fullName: "",
    email: "",
    contactNumber: "",
    gitRepoLink: "",
    websiteUrl: "",
    date: new Date().toISOString().split("T")[0], // current date in YYYY-MM-DD format
    time: new Date().toLocaleTimeString("it-IT").slice(0, 5), // current time in HH:MM format
  };
  const [formData, setFormData] = useState(initialFormData);
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const captchaValue = recaptchaRef.current.getValue();
    if (formData.contactNumber.length > 15) {
      toast.error("The contact number must be less than 15 characters");
      return;
    } else if (!emailPattern.test(formData.email)) {
      toast.error("Please enter a valid email address");
      return;
    } else if (!captchaValue) {
      toast.error("Please verify that you are not a robot.");
      return;
    }
    setIsLoading(true); // show loader
    const data = new FormData();
    const html = `<p>A new request for evaluation has been received:</p>
        <p><strong>Full Name:</strong> ${formData.fullName}</p>
        <p><strong>Email:</strong> ${formData.email}</p>
        <p><strong>Contact Number:</strong> ${formData.contactNumber}</p>
        <p><strong>Git Repository Link:</strong> <a href="${
          formData.gitRepoLink
        }">${formData.gitRepoLink}</a></p>
        <p><strong>Website URL:</strong> <a href="${formData.websiteUrl}">${
      formData.websiteUrl
    }</a></p>
        <p><strong>Date:</strong> ${formData.date}</p>
        <p><strong>Time:</strong> ${formData.time}</p>
        <p><strong>isMutual:</strong> ${isChecked ? "Yes" : "No"}</p>`;
    const subject = "New Submission to request for evaluation";
    const heading = "Request for Evaluation";
    data.append("email", formData.email);
    data.append("subject", subject);
    data.append("heading", heading);
    data.append("htmlContent", html);
    SendEmail(data).then((response) => {
      // console.log(response.data);
      setIsLoading(false); // hide loader

      if (response.data.status) {
        // alert(response.data.message);
        toast.success(response.data.message);
        setFormData(initialFormData);
      } else {
        setFormData(initialFormData);
        const errorText =
          response.data?.data?.details.length > 0
            ? response.data.data.details[0].message
            : response.data.message;
        toast.error(errorText);
      }
      recaptchaRef.current.reset();
    });
  };
  const isSubmit =
    formData.fullName && formData.email && formData.contactNumber;
  return (
    <>
      <Modal
        show={true}
        // eslint-disable-next-line react/prop-types
        onHide={props.onClose}
        dialogClassName="modal-90w"
        aria-labelledby="modal-title"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img src={dots} alt="Banner" className="dots top-right" />
          <img src={dots} alt="Banner" className="dots left-bottom" />
          <Row>
            <Col xs={12} md={12}>
              <div className="popup-header">
                <p className="sub-title">Have a query?</p>
                <h2>
                  Request for <b>Evaluation</b>
                </h2>
                <p className="help-text">
                  Fill out this form and pick a time slot for discover session
                  with team.
                </p>
              </div>
            </Col>
            <Col xs={12} md={12}>
              <form action="#" className="form full-form">
                <Row>
                  <Col xs={12} md={4}>
                    <div className="form-group">
                      <label htmlFor="name">
                        Your Name <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <input
                        type="text"
                        name="fullName"
                        className="form-control"
                        required
                        placeholder="Full name"
                        value={formData.fullName}
                        onChange={handleInputChange}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={4}>
                    <div className="form-group">
                      <label htmlFor="name">
                        Contact Number <sup style={{ color: "red" }}>*</sup>{" "}
                      </label>
                      <input
                        type="number"
                        name="contactNumber"
                        className="form-control"
                        required
                        placeholder="Ex.: 9887469725"
                        value={formData.contactNumber}
                        onChange={handleInputChange}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={4}>
                    <div className="form-group">
                      <label htmlFor="name">
                        Email <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <input
                        type="email"
                        name="email"
                        required
                        className="form-control"
                        placeholder="test@example.com"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={4}>
                    <div className="form-group">
                      <label htmlFor="name">Git Repo Link</label>
                      <input
                        type="url"
                        name="gitRepoLink"
                        className="form-control"
                        placeholder="Link"
                        value={formData.gitRepoLink}
                        onChange={handleInputChange}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={4}>
                    <div className="form-group">
                      <label htmlFor="name">Date</label>
                      <input
                        type="Date"
                        name="date"
                        className="form-control"
                        placeholder="Link"
                        min={new Date().toISOString().split("T")[0]}
                        value={formData.date}
                        onChange={handleInputChange}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={4}>
                    <div className="form-group">
                      <label htmlFor="name">Time</label>
                      <input
                        type="Time"
                        name="time"
                        className="form-control"
                        placeholder="Link"
                        value={formData.time}
                        onChange={handleInputChange}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={12}>
                    <div className="form-group">
                      <label htmlFor="name">Website URL</label>
                      <input
                        type="url"
                        className="form-control"
                        name="websiteUrl"
                        placeholder="Link"
                        value={formData.websiteUrl}
                        onChange={handleInputChange}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="form-group">
                      <Form.Check
                        inline
                        label="Yes, send me a mutual NDA (Non-Disclosure Agreement)"
                        id="checkbox_id"
                        htmlFor="checkbox_id"
                        name="group1"
                        type={"checkbox"}
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={googleReCaptchaKey}
                        onChange={onChange}
                      />
                    </div>
                    <p className="mt-1">
                      {" "}
                      <strong>
                        {" "}
                        All project secured by NDA & IP is your’s{" "}
                      </strong>
                    </p>
                    <p className="mt-2">
                      <strong> 100% Secure. Zero Spam. </strong>
                    </p>
                  </Col>
                  <Col xs={12} md={6} className="btnForm">
                    <div className="form-group  mb-0">
                      {isLoading ? (
                        <span className="btn loader float-end d-flex "></span>
                      ) : (
                        <input
                          type="Submit"
                          disabled={!isSubmit} // Disable button if form is not valid or if it's loading
                          onClick={onSubmit}
                          className="btn btn-submit"
                          value="Submit"
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RequestEvaluation;
