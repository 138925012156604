import React from "react";
import MenuBar from "./comman/MenuBar";
import Footer from "./comman/Footer";

import { Container, Row, Col } from "react-bootstrap";
import { dots } from "../assets";
import bannerImage from "../assets/inner-bg.webp";

const PrivacyPolicy = () => {
  return (
    <div className="PrivacyPolicy">
      <MenuBar />
      {/* Banner Section */}
      <Container fluid>
        <Row>
          <div className="bannerArea animation">
            <img
              src={bannerImage}
              alt="Banner"
              className="w-100 banner-image svg"
            />
            <img src={dots} alt="Banner" className="dots left svg" />
            <img src={dots} alt="Banner" className="dots right svg" />
            <Container>
              <Row>
                <Col xs={12} md={12}>
                  <div className="banner-caption inner-caption">
                    <h1 className="banner-title">PrivacyPolicy</h1>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Row>
      </Container>
      {/* Banner Section */}

      {/* Banner Section */}
      <Container>
        <Row>
          <Col md={12}>
            <div className="content-wrapper">
              <p>
                This privacy policy outlines what data Scrutinys Pvt. Ltd.
                (herein referred to as &quot;Scrutinys&quot;, &quot;Scrutinysinfo&quot;, &quot;We&quot;,
                  &quot;Our&quot;, or &quot;Us&quot;) collects, stores, and process about you. It also
                explains your rights to your data and how you can contact us if
                you have any questions. We reserve the right to alter this
                privacy statement at our discretion and encourage you to check
                this section from time to time to be aware of any changes.
              </p>

              <p>
                <strong>1. To whom this policy applies</strong>
              </p>
              <p>
                This privacy statement applies to individuals (&quot;users&quot; or &quot;you&quot;)
                who interact with our website, subscribe to any of our products,
                request information, or use the chat support (herein
                collectively referred to as &quot;services&quot;).
              </p>

              <p>
                <strong>2. Cookies:</strong>
              </p>
              <p>
                Cookies are small files placed on your computer when you first
                visit our website that helps analyze web traffic and tracks you
                across the web. The collection of this data is based on “implied
                consent”, i.e we assume you agree to our terms of usage unless
                you explicitly opt out. You can opt out by changing your browser
                settings to not accept cookies, browsing in anonymous mode, or
                by not using our website. We also have on our website links to
                many third-party services and use third-party tools, the privacy
                policy of which may be different from us and we assume no
                responsibility for their compliance.
              </p>
              <p>
                <strong>3. External tools:</strong>
              </p>
              <p>
                We also use some tools to collect data about your usage and
                behavior beyond our website that include:
              </p>
              <li>
                <strong>MailChimp - </strong>
                Promotional emails we send are tracked to know much traffic they
                bring on our website. We may also track if an individual email
                is opened by any recipient to calculate click rates and improve
                engagement. We use MailChimp as our email marketing tool and
                they are governed by their own privacy policy.
              </li>
              <li>
                <strong>Social plugins - </strong>
                We have on our website links to many social media platforms for
                more engagement and visibility. Once you click on any of those
                links to visit any of those platforms, they collect, use, and
                share your data as per their own privacy policies, of which we
                assume no responsibility. We encourage you to check out the
                privacy policies of the social platform we use:{" "}
                <a
                  href="https://www.facebook.com/scrutinys"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#337ab7" }}
                >
                  Facebook
                </a>
                ,
                <a
                  href="https://twitter.com/scrutinys_llp"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#337ab7" }}
                >
                  Twitter
                </a>
                ,
                <a
                  href="https://www.instagram.com/scrutinys.llp/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#337ab7" }}
                >
                  Instagram
                </a>
                ,
                <a
                  href="https://www.linkedin.com/company/scrutinys"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#337ab7" }}
                >
                  LinkedIn
                </a>
                .
              </li>
              <p>
                <strong>4. How we secure your data</strong>
              </p>
              <p>
                The security of your personal data is of utmost importance to us
                and we take all appropriate measures to protect it. There are
                requisite technical, administrative, and physical barriers in
                place to ensure your data is accessible only to authorized
                persons or systems. In case we discover a data breach, we
                immediately (within 72 hours) inform the local authorities and
                affected users.
              </p>
              <p>
                <strong>5. How we use your personal data</strong>
              </p>
              <p>
                We store and process the data we collect for our legitimate
                interests and service delivery fully under the ambit of law. We
                use your data:
              </p>
              <li>To provide and maintain our services.</li>
              <li>To improve our services and website experience.</li>
              <li>
                To communicate with you via email, notification, or any other
                contact information that you have provided.
              </li>
              <li>
                To provide any third-party requisite data that helps enable our
                services.
              </li>
              <li>For internal research and management.</li>
              <li>For fraud detection.</li>
              <li>To enforce our terms and conditions.</li>

              <p>
                <strong>6. What you can do</strong>
              </p>
              <p>
                You have full control over what information we store and its
                subsequent usage. You can choose to:
              </p>
              <li>Your explicit consent is obtained.</li>
              <li>Mandated by law.</li>
              <li>
                Third-party is a collaborator or a part (subsidiary,
                parent/sister company) of Scrutinys.
              </li>
              <li>
                To provide any third-party requisite data that helps enable our
                services.
              </li>
            </div>
          </Col>
        </Row>
      </Container>
      {/* Banner Section */}
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
